import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { HourglassEmpty, HourglassFull, CheckCircle } from '@mui/icons-material';
import { fetchTenantApplicationStatus } from '../../../api/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../redux/reducers/ErrorReducer';
 
  export const StatusPage = () => {
    const [status, setStatus] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { tenant_id } = useParams<{ tenant_id: string }>();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSignLease = () => {
      // Logic for handling forgot password
      navigate(`/signUp/leaseSigning/${tenant_id}`);
    };

    useEffect(() => {
      if (!tenant_id) {
        dispatch(setErrorReducer('Tenant id is not defined'));
        return;
      }
      fetchTenantApplicationStatus(parseInt(tenant_id)).then(res => {
        if (res.applications[0]) {
          setStatus(res.applications[0].status.name);
        } else {
          setStatus("No applications found");
        }
        setIsLoading(false);
      }).catch(error => {
        dispatch(setErrorReducer("An error occurred fetching status information"));
        setIsLoading(false);
      });
    }, [tenant_id, dispatch]);
  
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" marginTop="-10rem" alignItems="center" height="100vh">
        <Typography variant="h4" mb={4}>Application Status</Typography>
        {isLoading ? (
          <Typography variant="h6">Loading...</Typography>
        ) : (
          <>
              {status === "APPROVAL_PENDING" && <HourglassEmpty sx={{ fontSize: 100 }} />}
            {status === "UNDER_REVIEW" && <HourglassFull sx={{ fontSize: 100 }} />}
            {status === "APPROVED" && <CheckCircle sx={{ fontSize: 100, color: 'success.main' }} />}
            <Typography variant="h5">
              {status === "APPROVAL_PENDING" && 'Your application has been submitted and is pending. Log in to see status updates.'}
              {status === "UNDER_REVIEW" && 'Your application is under review. Log in to see status updates.'}
              {status === "APPROVED" && 'Congratulations! Your application has been approved. You can now sign your lease.'}
              {status === "No applications found" && 'No applications found for this tenant.'}
              {status === "Error fetching status" && 'Error fetching application status.'}
            </Typography>
            {status === "APPROVED" && (
              <Button variant="contained" color="primary" style={{marginTop: "1rem"}} onClick={handleSignLease}>Sign Lease</Button>
            )}
          </>
        )}
      </Box>
    );
  };