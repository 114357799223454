import { Button, TextField, Link, Box } from '@mui/material';
import { useState } from 'react';
import { fetchTenantLogin } from '../../../api/api'; // update this import path to your actual file
import { validateAndFormatPhoneNumber } from '../../../assets/helpers/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../redux/reducers/ErrorReducer';

export const SignIn = () => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const redux_tenant_id = useSelector((state: RootState) => state.app.userSlice.tenant_id);
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const handleForgotPassword = () => {
    // Logic for handling forgot password
    window.location.href = `/signIn/forgotPassword`;
  };

  useEffect(() => {
    if (redux_tenant_id  > 0 ) {
      // navigate(`/portal/home/${redux_tenant_id}`);
      window.location.href = `/portal/home/${redux_tenant_id}`;
    }
  }, [redux_tenant_id]);


  const handleSignIn = async () => {
    const formattedPhone = validateAndFormatPhoneNumber(phone);
    if (!formattedPhone) {
      console.log("Phone is not formatted correctly")
      return;
    }
    try {
      const response = await fetchTenantLogin({ phone: formattedPhone, password });

      if (response.is_customer > 0) {
        const tenant_id = response.is_customer;
        // If the user is a tenant, redirect to the home page
        // navigate(`/portal/home/${tenant_id}`);
        window.location.href = `/portal/home/${tenant_id}`;
      } else {
         // If the user isn't a tenant, show an error
        dispatch(setErrorReducer("Invalid phone number or password"));
       

      }
    } catch (err) {
      console.error(err);
      // If the user isn't a tenant, show an error
      dispatch(setErrorReducer("Invalid phone number or password"));
    }
  };

  const handleApplyNow = () => {
    // Logic for handling apply now
    window.location.href = '/signUp/basicInformation';
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Box maxWidth={400} width="100%" p={3}>
        <TextField
          label="Phone Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSignIn}
          style={{ marginTop: "0.5rem" }}
        >
          Sign In
        </Button>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={handleApplyNow}
          style={{ marginTop: "1rem" }}
        >
          Apply Now
        </Button>
        <div>
          <Link href="#" onClick={handleForgotPassword}>
            Forgot Password
          </Link>
        </div>
      </Box>
    </Box>
  );
}