import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { LandingPage } from './pages/landingPage/LandingPage';
import { Container, Typography, Box } from '@mui/material';
import { NavBar } from './components/NavBar';
import { SignIn } from './pages/portal/signin/SignIn';
import { ListingsList } from './pages/listings/ListingsList';
import { Listing } from './pages/listings/Listing';
import { BasicInformation } from './pages/portal/signup/basicInformation';
import { LeaseSelector } from './pages/portal/signup/leaseSelector';
import { References } from './pages/portal/signup/references';
import { StatusPage } from './pages/portal/signup/statusPage';
import { PortalHome } from './pages/portal/portal/home';
import { AdditionalOccupants } from './pages/portal/signup/additionalOccupants';
import { Pets } from './pages/portal/signup/pets';
import { EmploymentInformation } from './pages/portal/signup/employmentInformation';
import { EmergencyContactInfo } from './pages/portal/signup/emergencyContactInformation';
import { PersonalReferences } from './pages/portal/signup/personalReferenceInformation';
import { VehicleInformation } from './pages/portal/signup/vehicleInformation';
import { BackgroundInformation } from './pages/portal/signup/backgroundInformation';
import { PaymentInformation } from './pages/portal/signup/paymentInformation';
import { LeaseSigning } from './pages/portal/signup/leaseSigning';
import ForgotPassword from './pages/portal/signin/forgotPassword';
import ErrorBar from './components/errorBar';
import { TermsOfService } from './components/termsOfService';
import { PrivacyPolicy } from './components/privacyPolicy';
// import { useNavigate } from 'react-router-dom';

function App() {

  // const navigate = useNavigate();

  const goToTermsAndConditions = () => {
    window.location.href = '/termsOfService';
    // navigate('/termsOfService');
  };

  const goToPrivacyPolicy = () => {
    window.location.href = '/privacyPolicy'
    // navigate('/privacyPolicy');
  };


  return (
    <div className="App" style={{ position: 'relative' }}>
      <Router>
        <div style={{ marginBottom: '4rem' }}>

          <NavBar />
        </div>
        <ErrorBar />

        {/* These routes define the url paths that we can visit. These components associated with each path will render
            when we visit that url extension. e.g. http://localhost:3000/listings */}
        <div style={{ marginBottom: '4rem', paddingBottom: '120px' }}>
          <Routes>
            <Route path="/listing/:property_id" element={<Listing />} />
            <Route path="/termsOfService" element={<TermsOfService />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/listings" element={<ListingsList />} />
            <Route path="/signIn" element={<SignIn />} />
            <Route path="/signIn/forgotPassword" element={<ForgotPassword />} />
            <Route path="/signUp/basicInformation" element={<BasicInformation />} />
            <Route path="/signUp/leaseSelector/:tenant_id" element={<LeaseSelector />} />
            <Route path="/signUp/references/:tenant_id" element={<References />} />
            <Route path="/signUp/statusPage/:tenant_id" element={<StatusPage />} />
            <Route path="/signUp/additionalOccupants/:tenant_id" element={<AdditionalOccupants />} />
            <Route path="/signUp/pets/:tenant_id" element={<Pets />} />
            <Route path="/signUp/employmentInformation/:tenant_id" element={<EmploymentInformation />} />
            <Route path="/signUp/emergencyContacts/:tenant_id" element={<EmergencyContactInfo />} />
            <Route path="/signUp/personalReferences/:tenant_id" element={<PersonalReferences />} />
            <Route path="/signUp/vehicleInformation/:tenant_id" element={<VehicleInformation />} />
            <Route path="/signUp/backgroundInformation/:tenant_id" element={<BackgroundInformation />} />
            <Route path="/signUp/paymentInformation/:tenant_id" element={<PaymentInformation />} />
            <Route path="/signUp/leaseSigning/:tenant_id" element={<LeaseSigning />} />
            <Route path="/portal/home/:tenant_id" element={<PortalHome />} />
            <Route path="/" element={<LandingPage />} />
          </Routes>

        </div>

      </Router>
      <Box mt={5} bgcolor="secondary.main" color="white" p={2} >
        <Container maxWidth="sm">
          <Typography variant="body1" align="center">
            © {new Date().getFullYear()} Octavian Software LLC
          </Typography>

          <Typography variant="body2" align="center" style={{ cursor: 'pointer' }} onClick={goToTermsAndConditions}>
            Terms and Conditions
          </Typography>
          <Typography variant="body2" align="center" style={{ cursor: 'pointer' }} onClick={goToPrivacyPolicy}>
            Privacy Policy
          </Typography>

        </Container>
      </Box>
    </div>
  );
}

export default App;
