import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Checkbox, FormControlLabel, Card } from '@mui/material';
import { BackgroundInformationState } from '../../../assets/types/types';
import { postTenantBackgroundInformation } from '../../../api/api';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../redux/reducers/ErrorReducer';

interface FormErrors {
  propertyName?: string;
  city?: string;
  state?: string;
  landlordName?: string;
}

export const BackgroundInformation = () => {
  const [state, setState] = useState<BackgroundInformationState>({
    filedForBankruptcy: false,
    refusedToPayRent: false,
    evictedOrOwedMoney: false,
    propertyName: "",
    city: "",
    state: "",
    landlordName: "",
  });
  const { tenant_id } = useParams<{ tenant_id: string }>();

  const [errors, setErrors] = useState<FormErrors>({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = { ...state, [event.target.name]: event.target.checked };
    setState(newState);
    if (event.target.name === 'evictedOrOwedMoney' && !event.target.checked) {
      // If checkbox is unchecked, we clear the errors
      setErrors({});
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const validateFields = () => {
    let newErrors: FormErrors = {};
    if (state.evictedOrOwedMoney) {
      if (!state.propertyName) newErrors.propertyName = "Property name is required";
      if (!state.city) newErrors.city = "City is required";
      if (!state.state) newErrors.state = "State is required";
      if (!state.landlordName) newErrors.landlordName = "Landlord name is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateFields()) {
      return;
    }

    if (!tenant_id) {
      dispatch(setErrorReducer('Tenant id is not defined'));
      return;
    }

    const response = await postTenantBackgroundInformation(parseInt(tenant_id), state);
    if (response.error) {
      dispatch(setErrorReducer(response.message));
    } else {
      navigate(`/signUp/paymentInformation/${tenant_id}`);
    }
  };

  return (
    <Card style={{ padding: "2rem" }}>
      <Box display="flex" flexDirection="column">
        <FormControlLabel
          control={<Checkbox checked={state.filedForBankruptcy} onChange={handleChange} name="filedForBankruptcy" />}
          label="Filed for bankruptcy?"
        />
        <FormControlLabel
          control={<Checkbox checked={state.refusedToPayRent} onChange={handleChange} name="refusedToPayRent" />}
          label="Willfully or intentionally refused to pay rent when due?"
        />
        <FormControlLabel
          control={<Checkbox checked={state.evictedOrOwedMoney} onChange={handleChange} name="evictedOrOwedMoney" />}
          label="Been evicted from a tenancy or left owing money?"
        />

        {state.evictedOrOwedMoney && (
          <div>
            <TextField
              label="Property Name"
              value={state.propertyName}
              onChange={handleInputChange}
              error={!!errors.propertyName}
              helperText={errors.propertyName}
              name="propertyName"
            />

            <TextField
              label="City"
              value={state.city}
              onChange={handleInputChange}
              error={!!errors.city}
              helperText={errors.city}
              name="city"
            />

            <TextField
              label="State"
              value={state.state}
              onChange={handleInputChange}
              error={!!errors.state}
              helperText={errors.state}
              name="state"
            />

            <TextField
              label="Landlord Name"
              value={state.landlordName}
              onChange={handleInputChange}
              error={!!errors.landlordName}
              helperText={errors.landlordName}
              name="landlordName"
            />
          </div>
        )}

        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Next
        </Button>
      </Box>
    </Card>
  );
};