import { Document, Page } from 'react-pdf';
import { useState, useEffect, useCallback } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { fetchTenantSignedLease } from '../../../../api/api';
import { pdfjs } from 'react-pdf';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../../redux/reducers/ErrorReducer';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function base64ToUint8Array(base64: string): Uint8Array {
  const raw = atob(base64);
  const uint8Array = new Uint8Array(raw.length);
  for (let i = 0; i < raw.length; i++) {
    uint8Array[i] = raw.charCodeAt(i);
  }
  return uint8Array;
}

const Leases = () => {
  const [leaseAgreement, setLeaseAgreement] = useState<string | ArrayBuffer | null>(null);
  const { tenant_id } = useParams<{ tenant_id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const dispatch = useDispatch();


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchLease = useCallback(async () => {
    if (tenant_id) {
      try {
        setIsLoading(true)
        // Uncomment below line if you want to fetch from API

        const response = await fetchTenantSignedLease(parseInt(tenant_id));

        if (!response.ok) {  // Check if response status code is not in the range 200-299
          dispatch(setErrorReducer("An error occurred fetching Lease Information."));
        }

        const lease_document = response.lease_documents[0].data;
        // For now, we'll use the sample lease
        const byteArray = base64ToUint8Array(lease_document);
        const blob: Blob = new Blob([byteArray], { type: 'application/pdf' });

        // Convert blob to an Object URL and set it as state
        const blobURL = URL.createObjectURL(blob);
        setLeaseAgreement(blobURL);
        setIsLoading(false)
      } catch (error) {
        dispatch(setErrorReducer("An error occurred fetching Lease Information."));
        console.error(error);
      }
    }
  }, [tenant_id, dispatch]);

  useEffect(() => {
    fetchLease();
  }, [fetchLease]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ '& > *': { m: 1, width: '50ch' } }} marginLeft={isMobile ? "0rem" : "30rem"}>
      <Typography variant="h4" marginLeft={isMobile ? "0rem" : "-8rem"} marginBottom="5rem">Lease Agreement</Typography>
      {leaseAgreement && (
        <Document file={leaseAgreement.toString()}>
          <Page pageNumber={1} />
        </Document>
      )}
    </Box>
  );
};

export default Leases;
