// Define the action interface for login
export interface SuccessAction {
    type: "SET_SUCCESS", // Action type
    payload: string, // Payload containing the error message
  }
  
  // Define the union type of login and logout actions
  type AuthAction = SuccessAction;
  
  // Define the login reducer
  const SuccessReducer = (state: string = '', action: AuthAction) => {
    switch (action.type) {
      case "SET_SUCCESS":
        return action.payload; // Set the state to the payload value when the SET_SUCCESS action is dispatched
      default:
        return state; // Return the current state when the reducer is initialized
    }
  };
  
  // Action creator function for setting the error message
  const setSuccessReducer = (errorMessage: string): SuccessAction => {
    return {
      type: "SET_SUCCESS",
      payload: errorMessage,
    };
  };
  
  export { SuccessReducer, setSuccessReducer }; // Export the login reducer and action creator
  
  