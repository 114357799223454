import React from 'react';
import { Container, Paper, Typography } from '@mui/material';

export const PrivacyPolicy = () => {
    return (
        <Container maxWidth="md">
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography variant="h4" gutterBottom>
                    PRIVACY POLICY

                </Typography>
                <Typography variant="h6" gutterBottom>
                    Last Updated: August 07 2023
                </Typography>
                <Typography variant="h6" gutterBottom>
                    1. INTRODUCTION
                </Typography>
                <Typography variant="body1" paragraph>
                    Octavian Software LLC ("we," "our," or "us") operates the Octavian Real Estate application (the "App"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit or use the App.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    2. INFORMATION COLLECTION
                </Typography>
                <Typography variant="body1" paragraph>
                    We may collect information about you in various ways, including:

                    Personal Data: When you register, apply, request tours, or manage rentals, we may collect personally identifiable information such as your name, email address, phone number, etc.
                    Usage Data: We may collect information about how the App is accessed and used. Usage Data may include information such as your computer's Internet Protocol address, browser type, browser version, etc.
                    Cookies and Tracking Technologies: We may use cookies and similar tracking technologies to track the activity on our App.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    3. USE OF INFORMATION
                </Typography>
                <Typography variant="body1" paragraph>
                    We may use the information collected for various purposes, including:

                    To provide and maintain our App.
                    To notify you about changes to our App.
                    To allow you to participate in interactive features of our App when you choose to do so.
                    To provide customer support.
                    To gather analysis or valuable information to improve our App.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    4. DISCLOSURE OF INFORMATION
                </Typography>
                <Typography variant="body1" paragraph>
                    We may disclose your information:

                    To comply with legal obligations.
                    To protect and defend our rights and property.
                    To prevent or investigate wrongdoing in connection with the App.
                    To protect the personal safety of users or the public.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    5. SECURITY OF INFORMATION
                </Typography>
                <Typography variant="body1" paragraph>
                    We strive to maintain reasonable administrative, physical, and technical safeguards to protect the confidentiality and integrity of your personal information. However, no method of transmission or electronic storage is completely secure.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    6. THIRD-PARTY WEBSITES
                </Typography>
                <Typography variant="body1" paragraph>
                    The App may contain links to third-party websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    7. CHILDREN'S PRIVACY
                </Typography>
                <Typography variant="body1" paragraph>
                    Our App is not intended for use by children under the age of 13. We do not knowingly collect personally identifiable information from children under 13.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    8. CHANGES TO THIS PRIVACY POLICY
                </Typography>
                <Typography variant="body1" paragraph>
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    9. CONTACT US
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have any questions about this Privacy Policy, please contact us at varindra@octavian-software.com. </Typography>

            </Paper>
        </Container>
    );
};
