//this file might not ever be user. might want to remove
// import LoginReducer from "./LoginReducer";
import { ErrorReducer } from './reducers/ErrorReducer';
import { SuccessReducer } from "./reducers/SuccessReducer";
import { combineReducers } from "redux";
// import cartSlice from "./cartSlice";
import userSlice from "./slices/userSlice";

import { CombinedState } from '@reduxjs/toolkit'
// import { CartState } from './cartSlice'
import { UserState } from './slices/userSlice'

export interface RootState {
    app: CombinedState<{
    //   LoginReducer: boolean
      Error: string
      Success: string
    //   cartSlice: CartState
      userSlice: UserState
    }>
}

const allReducers = combineReducers({
    // LoginReducer: LoginReducer,
    Error: ErrorReducer,
    Success: SuccessReducer,
    // cartSlice: cartSlice,
    userSlice: userSlice
});

export default allReducers;