import { useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Card, Typography } from '@mui/material';
import { useEffect } from 'react';
import { EmploymentInfo } from '../../../assets/types/types';
import { postTenantEmploymentInfo } from '../../../api/api';
import { fetchTenantEmploymentInformation } from '../../../api/api';
import { validateDateInFuture } from '../../../assets/helpers/helpers';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../redux/reducers/ErrorReducer';

interface FormErrors {
  occupation?: string;
  company?: string;
  monthlySalary?: string;
  supervisorName?: string;
  supervisorPhone?: string;
  startDate?: string | null;
  endDate?: string | null;
}

interface EmploymentInformationProps {
  isSignUp?: boolean;
}

const validateEmployment = (employment: EmploymentInfo) => {
  let errors: FormErrors = {};

  for (let key of Object.keys(employment)) {
    let value = employment[key as keyof EmploymentInfo];

    if (value instanceof Date) {
      value = value.toISOString();
    }

    const error = validate(key, String(value));
    if (error) {
      errors[key as keyof FormErrors] = error;
    }
  }

  return errors;
};

const validate = (name: string, value: any) => {
  if (value === null || value === undefined || String(value).trim() === "") {
    return `${name} is required`;
  }

  if ((name === 'startDate' || name === 'endDate') && !Date.parse(value)) {
    return 'Date is not valid';
  }

  if ((name === 'startDate' || name === 'endDate') && validateDateInFuture(value)) {
    return 'Date is not valid. Date must be in the past';
  }

  return null;
};

export const EmploymentInformation = ({ isSignUp = true }: EmploymentInformationProps) => {
  const [employments, setEmployments] = useState<Array<{ employment: EmploymentInfo; errors: FormErrors; isDisabled: boolean }>>([]);
  const [clicked, setClicked] = useState(false);
  const [isNewEmployment, setIsNewEmployment] = useState(false);
  const { tenant_id } = useParams<{ tenant_id: string }>();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const dispatch = useDispatch();


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const populateEmployments = useCallback(async () => {
    if (tenant_id) {
      try {
        const response = await fetchTenantEmploymentInformation(parseInt(tenant_id));
        let existingEmployments = response.employment_info.map((employment: any) => ({
          employment: {
            occupation: employment.occupation,
            company: employment.company,
            monthlySalary: employment.monthly_salary,
            supervisorName: employment.supervisor_name,
            supervisorPhone: employment.supervisor_phone,
            startDate: employment.start_date,
            endDate: employment.end_date
          },
          errors: validateEmployment(employment),
          isDisabled: true, // Existing employments are disabled
        }));

        // Ensure that there is at least 1 employment
        if (existingEmployments.length < 1) {
          const newEmployment: EmploymentInfo = {
            occupation: '',
            company: '',
            monthlySalary: '',
            supervisorName: '',
            supervisorPhone: '',
            startDate: new Date(),
            endDate: new Date()
          };
          setIsNewEmployment(true);
          const errors = validateEmployment(newEmployment);
          existingEmployments.push({ employment: newEmployment, errors, isDisabled: false });
        }

        setEmployments(existingEmployments);
      } catch (error) {
        dispatch(setErrorReducer("An error occurred fetching employment information."));
      }
    }
  }, [tenant_id, dispatch]);


  useEffect(() => {
    populateEmployments();
  }, [populateEmployments]);

  const handleAddEmployment = () => {
    let newEmployment: EmploymentInfo = {
      occupation: '',
      company: '',
      monthlySalary: '',
      supervisorName: '',
      supervisorPhone: '',
      startDate: null,
      endDate: null
    };
    let errors = validateEmployment(newEmployment);
    setEmployments([...employments, { employment: newEmployment, errors, isDisabled: false }]);
    setIsNewEmployment(true);
  };

  const handleEmploymentChange = (index: number, updatedEmployment: EmploymentInfo) => {
    let errors = validateEmployment(updatedEmployment);
    setEmployments(employments.map((emp, i) => i === index ? { employment: updatedEmployment, errors, isDisabled: emp.isDisabled } : emp));
  };

  const handleNextClick = async () => {
    setClicked(true);

    const hasErrors = employments.some(({ errors }) => {
      return Object.values(errors).some(fieldErrors => fieldErrors !== "" && fieldErrors !== undefined);
    });

    if (!hasErrors) {
      const dataToSend = employments.filter(({ isDisabled }) => !isDisabled).map(({ employment }) => employment);
      if (!tenant_id) {
        console.error('Tenant id is not defined');
        return;
      }
      try{
        await postTenantEmploymentInfo(parseInt(tenant_id), dataToSend);
      }catch{
        dispatch(setErrorReducer("An error occurred posting employment information."));
      }
      
      if (isSignUp) {
        navigate(`/signUp/emergencyContacts/${tenant_id}`);
      } else {
        setIsNewEmployment(false);
        populateEmployments();
      }
    }
  };

  return (
    <div style={{ marginTop: "5rem", marginLeft: isSignUp || isMobile ? "0rem" : "16rem" }}>
      <Typography variant="body1" paragraph>
        Please provide information about your past or current employment.
      </Typography>
      {employments.map((employment, index) => (
        <Card key={index} style={{ marginBottom: "2rem", padding: "2rem" }}>
          <Box key={index} display="flex" flexDirection="column" >
            {/* Employment inputs */}
            <TextField
              name="occupation"
              label="Occupation"
              value={employment.employment.occupation}
              onChange={(e) => handleEmploymentChange(index, { ...employment.employment, occupation: e.target.value })}
              error={clicked && !!employment.errors.occupation}
              helperText={clicked && employment.errors.occupation}
              style={{ marginTop: "2rem" }}
              disabled={employment.isDisabled}
            />

            <TextField
              name="employer"
              label="Employer/Company"
              value={employment.employment.company}
              onChange={(e) => handleEmploymentChange(index, { ...employment.employment, company: e.target.value })}
              error={clicked && !!employment.errors.company}
              helperText={clicked && employment.errors.company}
              style={{ marginTop: "2rem" }}
              disabled={employment.isDisabled}
            />

            <TextField
              name="salary"
              label="Monthly Salary"
              value={employment.employment.monthlySalary}
              onChange={(e) => handleEmploymentChange(index, { ...employment.employment, monthlySalary: e.target.value })}
              error={clicked && !!employment.errors.monthlySalary}
              helperText={clicked && employment.errors.monthlySalary}
              style={{ marginTop: "2rem" }}
              disabled={employment.isDisabled}
            />

            <TextField
              name="supervisorName"
              label="Supervisor Name"
              value={employment.employment.supervisorName}
              onChange={(e) => handleEmploymentChange(index, { ...employment.employment, supervisorName: e.target.value })}
              error={clicked && !!employment.errors.supervisorName}
              helperText={clicked && employment.errors.supervisorName}
              style={{ marginTop: "2rem" }}
              disabled={employment.isDisabled}
            />

            <TextField
              name="supervisorPhone"
              label="Supervisor Phone"
              value={employment.employment.supervisorPhone}
              onChange={(e) => handleEmploymentChange(index, { ...employment.employment, supervisorPhone: e.target.value })}
              error={clicked && !!employment.errors.supervisorPhone}
              helperText={clicked && employment.errors.supervisorPhone}
              style={{ marginTop: "2rem" }}
              disabled={employment.isDisabled}
            />

            {/* Employment duration inputs */}
            <TextField
              name={"startDate"}
              label={"Start Date"}
              onChange={(e) => handleEmploymentChange(index, { ...employment.employment, startDate: new Date(e.target.value) })}
              value={employment.employment.startDate}
              error={clicked && !!employment.errors.startDate}
              helperText={clicked && employment.errors.startDate}
              variant="outlined"
              fullWidth
              margin="normal"
              type={"date"}
              disabled={employment.isDisabled}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              name={"endDate"}
              label={"End Date"}
              onChange={(e) => handleEmploymentChange(index, { ...employment.employment, endDate: new Date(e.target.value) })}
              value={employment.employment.endDate}
              error={clicked && !!employment.errors.endDate}
              helperText={clicked && employment.errors.endDate}
              variant="outlined"
              fullWidth
              margin="normal"
              type={"date"}
              disabled={employment.isDisabled}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        </Card>
      ))}
      <Button onClick={handleAddEmployment}>Add Employment</Button>
      {isNewEmployment && <Button variant="contained" color="primary" onClick={handleNextClick}>Submit</Button>}
    </div>
  );
};