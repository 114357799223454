import { Button, Typography, Card, CardContent, Grid, Divider, Box, CircularProgress } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { fetchListings } from '../../api/api';
import { useEffect, useState } from 'react';
import { Listing } from '../../assets/types/types';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../redux/reducers/ErrorReducer';

const mapContainerStyle = {
    width: '100%',
    height: '100vh',
};

const center = {
    lat: 30.2672, // Center latitude for the map
    lng: -97.7431 // Center longitude for the map
};

const handleViewListing = (listing_id: number) => {
    window.location.href = `/listing/${listing_id}`;
};

const handleApplyNow = () => {
    window.location.href = '/signUp/basicInformation';
};

export const ListingsList = () => {
    const [listings, setListings] = useState<Listing[]>([]);
    const dispatch = useDispatch();

    // Fetch listings from the backend when the component mounts
    useEffect(() => {
        fetchListings().then(data => {
            setListings(data.properties); // Update the state with the fetched listings
            console.log(data.properties)
        }).catch(error => {
            console.error('Error:', error);
            dispatch(setErrorReducer("An error occurred fetching listing data"));
        });
    }, [dispatch]); // Empty dependency array means this useEffect will run once when the component mounts

    if (!listings.length) {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <CircularProgress />
          </Box>
        );
      }


    return (
        <Grid container spacing={1} >
            <Grid item xs={12} md={8} style={{ marginTop: "1rem" }}>
                {listings.map((listing) => (
                    <Card key={listing.id} variant="outlined" style={{ marginBottom: '1px' }}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <div style={{ width: "15rem", marginLeft: "4rem", marginTop: "1rem" }}>
                                    <Carousel showArrows={true} showIndicators={true} showThumbs={false}>
                                        {listing.images.map((image, index) => (
                                            <div key={index} style={{ height: "15rem", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
                                                <img src={image} alt={`Slide ${index}`} style={{ objectFit: "cover", height: "100%", width: "100%" }} />
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CardContent>
                                    <Box mb={2}>
                                        <Typography variant="h5"> {listing.property_name}</Typography>
                                    </Box>
                                    <Divider />
                                    <Box mt={2} mb={2}>
                                        <Typography variant="h6">Available: {listing.availableFrom}</Typography>
                                        <Typography variant="body1">Price: ${listing.priceRange.min} - {listing.priceRange.max}</Typography>
                                        <Typography variant="body1">Bedrooms: {listing.bedrooms}</Typography>
                                        <Typography variant="body1">Bathrooms: {listing.bathrooms}</Typography>
                                        <Typography variant="body1">Square Footage: {listing.squareFootage} sqft</Typography>

                                    </Box>
                                    <Box mt={2}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ marginRight: "2rem" }}
                                            onClick={() => handleViewListing(listing.id)}
                                        >
                                            View Listing
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={handleApplyNow}>
                                            Apply Now
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                ))}
            </Grid>

            <Grid item xs={12} md={4} style={{ position: 'sticky', top: '0' }}>
                <Box sx={{ position: 'sticky', top: 0 }}>
                    <LoadScript googleMapsApiKey="AIzaSyDMxoAz8HBhAqBjtOuz02x-nnNSmmUG0pw">
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            center={center}
                            zoom={10}
                        >
                            {listings.map((listing) => (
                                <Marker
                                    key={listing.id}
                                    position={{
                                        lat: parseFloat(listing.location.lat),
                                        lng: parseFloat(listing.location.lng)
                                    }}
                                />
                            ))}
                        </GoogleMap>
                    </LoadScript>
                </Box>
            </Grid>

        </Grid>
    );
};