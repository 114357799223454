import { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Button, TextField, Box, MenuItem, Select, Card, CircularProgress, Typography } from '@mui/material';
import 'dayjs/locale/en';
import { Reference } from '../../../assets/types/types';
import { postTenantReferences } from '../../../api/api';
import { fetchTenantReferences } from '../../../api/api';
import { useNavigate } from 'react-router-dom';
import { validateDateInFuture, validateAndFormatPhoneNumber, validateZipCode } from '../../../assets/helpers/helpers';
import { setErrorReducer } from '../../../redux/reducers/ErrorReducer';
import { useDispatch } from 'react-redux';

interface FormErrors {
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state?: string;
  zip?: string;
  type?: string;
  startDate?: string | null;
  endDate?: string | null;
  contact?: {
    name?: string;
    phone?: string;
    email?: string;
  };
}

interface ReferencesProps {
  isSignUp?: boolean;
}

const validate = (name: string, value: any) => {
  const fieldsToSkipValidation: string[] = [];

  if (fieldsToSkipValidation.includes(name)) {
    return null;
  }

  if (value === null || value === undefined || String(value).trim() === "") {
    return `${name} is required`;
  }

  // Additional validations
  if (name === 'contact.email' && !/\S+@\S+\.\S+/.test(value)) {
    return 'Email is not valid';
  }

  if (name === 'contact.phone' && !validateAndFormatPhoneNumber(value)) {
    return 'Phone Number is not valid';
  }

  if (name === "zip" && !validateZipCode(value)) {
    return 'Zip Code is not valid';
  }

  if (((name === 'startDate' || name === 'endDate') && !Date.parse(value))) {
    return 'Date is not valid';
  }

  if ((name === 'startDate' || name === 'endDate') && validateDateInFuture(value)) {
    return 'Date is not valid';
  }

  return null;
};

const validateReference = (reference: Reference) => {
  let errors: FormErrors = {};

  // checking nested contact fields
  for (let contactKey of Object.keys(reference.contact)) {
    const value = reference.contact[contactKey as keyof typeof reference.contact];
    const error = validate(`contact.${contactKey}`, String(value));
    if (error) {
      errors.contact = { ...errors.contact, [contactKey]: error };
    }
  }

  // checking non-nested fields
  for (let key of Object.keys(reference)) {
    if (key !== 'contact') {
      let value = reference[key as keyof Omit<Reference, 'contact'>];

      // Convert Date objects to string
      if (value instanceof Date) {
        value = value.toISOString();
      }

      const error = validate(key, String(value));
      if (error) {
        errors[key as keyof FormErrors] = error;
      }
    }
  }

  return errors;
};

export const References = ({ isSignUp = true }: ReferencesProps) => {
  const [references, setReferences] = useState<Array<{ reference: Reference; errors: FormErrors; isDisabled: boolean }>>([]);
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isNewReference, setIsNewReference] = useState(false); // New state variable to track whether a new reference is being added or not.
  const { tenant_id } = useParams<{ tenant_id: string }>();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const dispatch = useDispatch();


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const populateReferences = useCallback(async () => {
    if (tenant_id) {
      try {
        const response = await fetchTenantReferences(parseInt(tenant_id));
        const existingReferences = response.references.map((reference: any) => ({
          reference: {
            address_line_1: reference.address_line_1,
            address_line_2: reference.address_line_2,
            city: reference.city,
            state: reference.state,
            zip: reference.zip,
            type: reference.type,
            startDate: reference.start_date,
            endDate: reference.end_date,
            contact: {
              name: reference.contact.name,
              phone: reference.contact.phone,
              email: reference.contact.email,
            },
          },
          isDisabled: true,
          errors: validateReference(reference),
        }));

        // Ensure that there are at least 2 references
        while (existingReferences.length < 2) {
          const newReference: Reference = {
            address_line_1: '',
            address_line_2: '',
            city: '',
            state: '',
            zip: '',
            type: 'Rent',
            startDate: new Date(),
            endDate: new Date(),
            contact: {
              name: '',
              phone: '',
              email: '',
            },
          };


          setIsNewReference(true);
          const errors = validateReference(newReference);
          existingReferences.push({ reference: newReference, errors, isDisabled: false });
        }

        setReferences(existingReferences);
        setLoading(false);
      } catch (error) {
        dispatch(setErrorReducer("An error occurred fetching reference information"));
        console.error('Failed to fetch reference information', error);
        setLoading(false);
      }
    }
  }, [tenant_id, dispatch]);

  useEffect(() => {
    populateReferences();
  }, [populateReferences, isNewReference]);

  const handleAddReference = () => {
    let newReference: Reference = {
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zip: '',
      type: "Rent",
      startDate: null,
      endDate: null,
      contact: {
        name: '',
        phone: '',
        email: '',
      },
    };
    let errors = validateReference(newReference);
    setReferences([...references, { reference: newReference, errors, isDisabled: false }]);
    setIsNewReference(true);
  };

  const handleReferenceChange = (index: number, updatedReference: Reference) => {
    let errors = validateReference(updatedReference);
    setReferences(references.map((ref, i) => i === index ? { reference: updatedReference, errors, isDisabled: ref.isDisabled } : ref));
  };

  const handleNextClick = async () => {
    setClicked(true);

    // Check if any reference object has validation errors
    const hasErrors = references.some(({ errors }) => {
      // Check if any of the error fields are not empty strings or undefined
      return Object.values(errors).some(fieldErrors => {
        if (typeof fieldErrors === 'object' && fieldErrors !== null) {
          return Object.values(fieldErrors).some(error => error !== "" && error !== undefined);
        }
        return fieldErrors !== "" && fieldErrors !== undefined;
      });
    });

    if (!hasErrors) {
      // If there are no errors, gather data to send via API
      const dataToSend = references.filter(({ isDisabled }) => !isDisabled).map(({ reference }) => reference);
      // Check that tenant_id is defined
      if (!tenant_id) {
        console.error('Tenant id is not defined');
        return;
      }

      // Send data to API
      // You can replace this with your actual API call
      try{
        await postTenantReferences(parseInt(tenant_id), dataToSend);

      }catch (error) {
        dispatch(setErrorReducer("An error occurred posting reference information"));
      }
     

      // Navigate to the next page
      if (isSignUp) {
        navigate(`/signUp/additionalOccupants/${tenant_id}`);
      } else {
        populateReferences();
        setIsNewReference(false);
      }
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div style={{ marginTop: "5rem", marginLeft: isSignUp || isMobile ? "0rem" : "16rem" }}>
      <Typography variant="body1" paragraph>
        Please provide us with at least 2 years of reference information. These should be properties that you have occupied in the past 2 years.
      </Typography>
      {references.map((reference, index) => (
        <Card key={index} style={{ marginBottom: "2rem", padding: "2rem" }}>
          <Box key={index} display="flex" flexDirection="column" >
            {/* Address input */}
            <TextField
              name="addLine1"
              label="Address Line 1"
              value={reference.reference.address_line_1}
              onChange={(e) => handleReferenceChange(index, { ...reference.reference, address_line_1: e.target.value })}
              error={clicked && !!reference.errors.address_line_1}
              helperText={clicked && reference.errors.address_line_1}
              disabled={reference.isDisabled}
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="addLine2"
              label="Address Line 2"
              value={reference.reference.address_line_2}
              onChange={(e) => handleReferenceChange(index, { ...reference.reference, address_line_2: e.target.value })}
              error={clicked && !!reference.errors.address_line_2}
              helperText={clicked && reference.errors.address_line_2}
              disabled={reference.isDisabled}
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="city"
              label="City"
              value={reference.reference.city}
              onChange={(e) => handleReferenceChange(index, { ...reference.reference, city: e.target.value })}
              error={clicked && !!reference.errors.city}
              helperText={clicked && reference.errors.city}
              disabled={reference.isDisabled}
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="state"
              label="State"
              value={reference.reference.state}
              onChange={(e) => handleReferenceChange(index, { ...reference.reference, state: e.target.value })}
              error={clicked && !!reference.errors.state}
              helperText={clicked && reference.errors.state}
              disabled={reference.isDisabled}
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="zip"
              label="Zip Code"
              value={reference.reference.zip}
              onChange={(e) => handleReferenceChange(index, { ...reference.reference, zip: e.target.value })}
              error={clicked && !!reference.errors.zip}
              helperText={clicked && reference.errors.zip}
              disabled={reference.isDisabled}
              style={{ marginTop: "2rem" }}
            />

            {/* Accommodation type select */}
            <Select
              value={reference.reference.type}
              onChange={(e) => handleReferenceChange(index, { ...reference.reference, type: e.target.value as "Rent" | "Own" })}
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
              disabled={reference.isDisabled}
            >
              <MenuItem value={"Rent"}>Rent</MenuItem>
              <MenuItem value={"Own"}>Own</MenuItem>
            </Select>

            <TextField
              name={"startDate"}
              label={"Lease Start Date"}
              value={reference.reference.startDate}
              onChange={(e) => handleReferenceChange(index, { ...reference.reference, startDate: new Date(e.target.value) })}
              error={clicked && !!reference.errors.startDate}
              helperText={clicked && reference.errors.startDate}
              disabled={reference.isDisabled}
              variant="outlined"
              fullWidth
              margin="normal"
              type={"date"}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              name={"endDate"}
              label={"Lease End Date"}
              value={reference.reference.endDate}
              onChange={(e) => handleReferenceChange(index, { ...reference.reference, endDate: new Date(e.target.value) })}
              error={clicked && !!reference.errors.endDate}
              helperText={clicked && reference.errors.endDate}
              disabled={reference.isDisabled}
              variant="outlined"
              fullWidth
              margin="normal"
              type={"date"}
              InputLabelProps={{ shrink: true }}
            />

            {/* Contact info inputs */}
            <TextField
              name="constactName"
              label="Contact Name"
              value={reference.reference.contact.name}
              onChange={(e) => handleReferenceChange(index, { ...reference.reference, contact: { ...reference.reference.contact, name: e.target.value } })}
              error={clicked && !!reference.errors?.contact?.name}
              disabled={reference.isDisabled}
              helperText={clicked && reference.errors?.contact?.name}
              style={{ marginTop: "2rem" }}
            />
            <TextField
              name="contactPhone"
              label="Contact Phone"
              value={reference.reference.contact.phone}
              onChange={(e) => handleReferenceChange(index, { ...reference.reference, contact: { ...reference.reference.contact, phone: e.target.value } })}
              error={clicked && !!reference.errors?.contact?.phone}
              disabled={reference.isDisabled}
              helperText={clicked && reference.errors?.contact?.phone}
              style={{ marginTop: "1rem" }}
            />
            <TextField
              name="contactEmail"
              label="Contact Email"
              value={reference.reference.contact.email}
              onChange={(e) => handleReferenceChange(index, { ...reference.reference, contact: { ...reference.reference.contact, email: e.target.value } })}
              disabled={reference.isDisabled}
              error={clicked && !!reference.errors?.contact?.email}
              helperText={clicked && reference.errors?.contact?.email}
              style={{ marginTop: "1rem" }}
            />
          </Box>
        </Card>
      ))}
      <Button onClick={handleAddReference}>Add Property</Button>
      {isNewReference && <Button variant="contained" color="primary" onClick={handleNextClick}>Submit</Button>}
    </div>
  );
};