import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { fetchLeaseSigningUrl } from "../../../api/api";
import { useDispatch } from "react-redux";
import { setErrorReducer } from "../../../redux/reducers/ErrorReducer";
import { Box, CircularProgress } from "@mui/material";

// Tenant will sign lease and complete signup process here
export const LeaseSigning = () => {
  const { tenant_id } = useParams<{ tenant_id: string }>();
  const [signingUrl, setSigningUrl] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!tenant_id) {
      console.log("Missing tenant id")
      return;
    }

    fetchLeaseSigningUrl(parseInt(tenant_id)).then(async data => {
      setSigningUrl(data.signing_urls[0]); // Update the state with the fetched listings

    }).catch(error => {
      dispatch(setErrorReducer("An error occurred fetching lease signing url."));
    });
  }, [tenant_id, dispatch]);

  return (
    <div>
      {signingUrl ? (
        <div>
        <h3>Lease Signing</h3>
        <iframe 
          src={signingUrl} 
          title="Lease Signing" 
          width="100%" 
          height="600" 
          style={{ border: 'none' }} 
        />
      </div>
      ) : (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
      )}
    </div>

  );
}