import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Card, CardContent, Grid, CircularProgress } from '@mui/material';
import { fetchTenantDashboardInformation } from '../../../../api/api';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../../redux/reducers/ErrorReducer';

interface INotification {
    type: string;
    message: string;
    date_created: string;
}

interface ICommunityInfo {
    general_description: string;
    neighborhood_description: string;
}

interface ITenantDashboardInfo {
    tenant_id: string;
    notifications: INotification[];
    num_active_requests: number;
    num_payments_due: number;
    community_info: ICommunityInfo;
}

const Dashboard = () => {
    const [data, setData] = useState<ITenantDashboardInfo | null>(null);
    const { tenant_id } = useParams<{ tenant_id: string }>();
    const dispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (!tenant_id) {
            console.error("Tenant Id not found")
            return;
        }
        fetchTenantDashboardInformation(parseInt(tenant_id))
            .then(res => setData(res))
            .catch(error => {
                console.error('Error:', error);
                dispatch(setErrorReducer("An error occurred fetching Dashboard Information."));
            });
    }, [tenant_id, dispatch]);

    if (!data) return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
        >
            <CircularProgress data-testid="loading-spinner-container" />
        </Box>);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" padding="2rem" marginLeft={isMobile ? "0rem" : "16rem"}>
            <Typography variant="h4" marginBottom="2rem"> Dashboard</Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" gutterBottom>Open Requests & Upcoming Payments</Typography>
                            <Typography>You have {data.num_active_requests} open requests</Typography>
                            <Typography>You have {data.num_payments_due} payments due</Typography>
                        </CardContent>
                    </Card>
                    <Card style={{ marginTop: '20px' }}>
                        <CardContent>
                            <Typography variant="h5" gutterBottom>Community Resources</Typography>
                            <Typography>{data.community_info.general_description}</Typography>
                            <Typography>{data.community_info.neighborhood_description}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" gutterBottom>Notifications</Typography>
                            {data.notifications.map((notification, index) => (
                                <Typography key={index}>{new Date(notification.date_created).toLocaleDateString()}: {notification.message}</Typography>
                            ))}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;