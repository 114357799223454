import { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Button, Box, MenuItem, FormControl, InputLabel, Select, TextField, CircularProgress } from '@mui/material';
import { SelectChangeEvent } from "@mui/material";
import 'dayjs/locale/en';
import { FormErrors } from '../../../assets/types/types';
import { fetchListings } from '../../../api/api';
import { Listing } from '../../../assets/types/types';
import { postCreateLease } from '../../../api/api';
import { PropertyDetails, Unit } from '../../../assets/types/types';
import { fetchPropertyDetails } from '../../../api/api';
import { validateDateInFuture } from '../../../assets/helpers/helpers';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../redux/reducers/ErrorReducer';

export const LeaseSelector = () => {
  const [listings, setListings] = useState<Listing[]>([]);
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [leaseStart, setLeaseStart] = useState("");
  const [leaseDuration, setLeaseDuration] = useState("12");
  const { tenant_id } = useParams<{ tenant_id: string }>();
  const [clicked, setClicked] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState(true);
  const [propertyDetails, setPropertyDetails] = useState<PropertyDetails | null>(null);
  const [selectedUnits, setSelectedUnits] = useState<Unit[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchListings().then(async data => {
      setListings(data.properties); // Update the state with the fetched listings
      setSelectedListing(data.properties[0]);
      const propertyDetails = await fetchPropertyDetails(data.properties[0].id);
      setPropertyDetails(propertyDetails);

      if (propertyDetails.units.length > 0) {
        setSelectedUnits([propertyDetails.units[0]]);
      }

      setLoading(false);
    }).catch(error => {
      dispatch(setErrorReducer("An error occurred fetching listing data."));
    });
  }, [dispatch]);


  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const handleSelectProperty = (listingId: number) => {
    setSelectedListing(listings.find(listing => listing.id === listingId) || null);
  };

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;

    switch (name) {
      case 'startDate':
        setLeaseStart(value as string);
        break;
    }
  };

  const handleSelectChange = async(event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;

    switch (name) {
      case 'property-selector':
        handleSelectProperty(parseInt(value));
        const newPropertyDetails = await fetchPropertyDetails(parseInt(value));
        setPropertyDetails(newPropertyDetails);
        setSelectedUnits([]);
        break;

      case 'unit-selector':
        let selectedUnitIds: string[];
        if (typeof event.target.value === 'string') {
          selectedUnitIds = [event.target.value];
        } else {
          selectedUnitIds = event.target.value;
        }
        const selectedUnits = selectedUnitIds.map(id => propertyDetails?.units.find((unit: Unit | undefined) => unit?.id.toString() === id)).filter((unit): unit is Unit => Boolean(unit));
        // const selectedUnits = selectedUnitIds.map(id => propertyDetails?.units.find(unit => unit.id.toString() === id)).filter((unit): unit is Unit => Boolean(unit));
        setSelectedUnits(selectedUnits);
        break;

      case 'leaseDuration':
        setLeaseDuration(value);
        break;
    }
  };

  const validateForm = () => {
    let formErrors: FormErrors = {};

    if (!leaseStart) {
      formErrors['startDate'] = 'Please select a start date';
    }

    const futureDateValidation = validateDateInFuture(leaseStart)
    if (!futureDateValidation) {
      formErrors['startDate'] = 'The date must be at least 2 days in the future';

    }
    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleNextClick = () => {
    setClicked(true);

    if (!validateForm()) {
      return;
    }

    //make api call to create lease
    postCreateLease(selectedListing!.id, leaseStart, leaseDuration, tenant_id!, selectedUnits).then((data: any) => {
      // Redirect or handle response data
      navigate(`/signUp/references/${tenant_id}`);
    })
    .catch(error => {
      dispatch(setErrorReducer("An error occurred posting lease data."));
    })
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <FormControl style={{ marginTop: "3.5rem", width: "20rem" }}>
          <InputLabel id={`property-selector-label`}>Unit</InputLabel>
          <Select
            name="property-selector"
            labelId={`property-selector-label`}
            id={`property-selector`}
            value={selectedListing?.id ? selectedListing.id.toString() : ""}
            onChange={handleSelectChange}
          >
            {listings.map((listing) => (
              <MenuItem key={listing.id} value={listing.id}> {listing.property_name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {propertyDetails && (
          <FormControl style={{ marginTop: "3.5rem", marginLeft: "1rem", width: "20rem" }}>
            <InputLabel id={`unit-selector-label`}>Unit</InputLabel>
            <Select
              name="unit-selector"
              labelId={`unit-selector-label`}
              id={`unit-selector`}
              multiple  // To select multiple units
              value={selectedUnits.map(unit => unit.id.toString()) as unknown as string}  // Now storing an array of selected unit IDs and converting to string
              onChange={handleSelectChange}  // Update to handle multiple units
            >
              {propertyDetails.units.map((unit) => (
                <MenuItem key={unit.id} value={unit.id.toString()}> {unit.unit_name}</MenuItem>  // Assuming each unit has a `unit_name` field
              ))}
            </Select>
          </FormControl>
        )}

        <Box marginLeft={2} marginTop="3rem">
          <TextField
            name={"startDate"}
            label={"Lease Start Date"}
            // value={values[name]}
            onChange={handleChange}
            error={clicked && !!errors["startDate"]}
            helperText={clicked && errors["startDate"]}
            variant="outlined"
            fullWidth
            margin="normal"
            type={"date"}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box marginLeft={2} marginTop="3rem">
          <Select
            name="leaseDuration"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={leaseDuration}
            fullWidth
            label="Age"
            onChange={handleSelectChange}
          >
            <MenuItem value={3}>3 Months</MenuItem>
            <MenuItem value={6}>6 Months</MenuItem>
            <MenuItem value={12}>12 Months</MenuItem>
            <MenuItem value={18}>18 Months</MenuItem>
          </Select>
        </Box>
      </Box>

      <Box marginLeft={2} marginTop="1rem">
        <Button variant="contained" color="primary" onClick={handleNextClick}>Next</Button>
      </Box>

    </div>

  );
};