// Define the action interface for login
interface ErrorAction {
    type: "SET_ERROR", // Action type
    payload: string, // Payload containing the error message
  }
  
  // Define the union type of login and logout actions
  type AuthAction = ErrorAction;
  
  // Define the login reducer
  const ErrorReducer = (state: string = '', action: AuthAction) => {
    switch (action.type) {
      case "SET_ERROR":
        return action.payload; // Set the state to the payload value when the SET_ERROR action is dispatched
      default:
        return state; // Return the current state when the reducer is initialized
    }
  };
  
  // Action creator function for setting the error message
  const setErrorReducer = (errorMessage: string): ErrorAction => {
    return {
      type: "SET_ERROR",
      payload: errorMessage,
    };
  };
  
  export { ErrorReducer, setErrorReducer }; // Export the login reducer and action creator