// this file will contain all of our api calls. As our app grows in complexity, we can separate this into multiple files
import { Unit, Reference, Occupant, Pet, EmploymentInfo, EmergencyContact, PersonalReference, Vehicle, BackgroundInformationState, MaintenanceRequest, PaymentData } from "../assets/types/types";
import { getCookie } from "./cookie";


export const fetchCsrfToken = async () => {
  const response = await fetch('https://backend.octavian-real-estate.com/get_csrf_token', {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  }); // replace with your endpoint
  if (!response.ok) {
    throw new Error('Failed to fetch csrf token');
  }
  const data = await response.json()
  // document.cookie = `csrftoken=${data.csrfToken}; Secure; HttpOnly`;
  document.cookie = `csrftoken=${data.csrfToken};`;// comment this, and uncomment the above line in production
};

export const fetchListings = async () => {
  const response = await fetch('https://backend.octavian-real-estate.com/get_properties'); // replace with your endpoint
  if (!response.ok) {
    throw new Error('Failed to fetch listings');
  }
  return await response.json();
};


export const fetchPropertyDetails = async (propertyId: number) => {
  const response = await fetch(`https://backend.octavian-real-estate.com/get_property_details/?property_id=${propertyId}`);
  if (!response.ok) {
    throw new Error('Failed to fetch property details');
  }
  return await response.json();
};

export const fetchTenantApplicationStatus = async (tenantId: number) => {
  const response = await fetch(`https://backend.octavian-real-estate.com/get_tenant_application_status/?tenant_id=${tenantId}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch tenant application status');
  }
  return await response.json();
};


export const fetchTenantSignedLease = async (tenantId: number) => {
  const response = await fetch(`https://backend.octavian-real-estate.com/get_tenant_signed_leases/?tenant_id=${tenantId}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch tenant leases');
  }
  return await response.json();
};

// Function for tenant login
export const fetchTenantLogin = async (loginData: { phone: string, password: string }) => {
  // Encode the phone number before using it in a URL
  const encodedPhoneNumber = encodeURIComponent(loginData.phone);

  const response = await fetch(`https://backend.octavian-real-estate.com/get_tenant_login/?phone=${encodedPhoneNumber}&password=${loginData.password}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  });
  await fetchCsrfToken();
  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return responseData;
};

export const fetchTenantPersonalInformation = async (tenant_id: number) => {
  const response = await fetch(`https://backend.octavian-real-estate.com/get_tenant_personal_information/?tenant_id=${tenant_id}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  }); // replace with your endpoint
  if (!response.ok) {
    throw new Error('Failed to tenantinformation');
  }
  return await response.json();
};

export const fetchTenantVehicleInformation = async (tenant_id: number) => {
  const response = await fetch(`https://backend.octavian-real-estate.com/get_tenant_vehicle_information/?tenant_id=${tenant_id}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  }); // replace with your endpoint
  if (!response.ok) {
    throw new Error('Failed to tenants vehicle information');
  }
  return await response.json();
};

export const fetchTenantPets = async (tenant_id: number) => {
  const response = await fetch(`https://backend.octavian-real-estate.com/get_tenant_pets/?tenant_id=${tenant_id}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  }); // replace with your endpoint
  if (!response.ok) {
    throw new Error('Failed to tenants pet information');
  }
  return await response.json();
};

export const fetchTenantEmploymentInformation = async (tenant_id: number) => {
  const response = await fetch(`https://backend.octavian-real-estate.com/get_tenant_employment_information/?tenant_id=${tenant_id}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  }); // replace with your endpoint
  if (!response.ok) {
    throw new Error('Failed to tenants employment information');
  }
  return await response.json();
};

export const fetchTenantEmergencyContacts = async (tenant_id: number) => {
  const response = await fetch(`https://backend.octavian-real-estate.com/get_tenant_emergency_contacts/?tenant_id=${tenant_id}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  }); // replace with your endpoint
  if (!response.ok) {
    throw new Error('Failed to tenants employment information');
  }
  return await response.json();
};

export const fetchTenantReferences = async (tenant_id: number) => {
  const response = await fetch(`https://backend.octavian-real-estate.com/get_tenant_references/?tenant_id=${tenant_id}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  }); // replace with your endpoint
  if (!response.ok) {
    throw new Error('Failed to tenants references');
  }
  return await response.json();
};

export const fetchTenantMaintenanceRequests = async (tenantId: number) => {
  const response = await fetch(`https://backend.octavian-real-estate.com/get_maintenance_requests/?tenant_id=${tenantId}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch maintenance requests');
  }
  return await response.json();
};

export const fetchStripeCustomerId = async (tenantId: number) => {
  const response = await fetch(`https://backend.octavian-real-estate.com/get_stripe_customer_information/?tenant_id=${tenantId}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch tenants stripe customer id');
  }
  return await response.json();
};

export const fetchTenantAccountBalance = async (tenantId: number) => {
  const response = await fetch(`https://backend.octavian-real-estate.com/get_tenant_account_balance/?tenant_id=${tenantId}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch tenant details');
  }
  return await response.json();
};

export const fetchTenantPayments = async (tenantId: number) => {
  const response = await fetch(`https://backend.octavian-real-estate.com/get_tenant_payments/?tenant_id=${tenantId}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch tenant payments');
  }
  return await response.json();
};

export const fetchTenantDueDates = async (tenantId: number) => {
  const response = await fetch(`https://backend.octavian-real-estate.com/get_tenant_due_dates/?tenant_id=${tenantId}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch tenant due dates');
  }
  return await response.json();
};

export const fetchTenantDashboardInformation = async (tenantId: number) => {
  const response = await fetch(`https://backend.octavian-real-estate.com/get_tenant_dashboard_info/?tenant_id=${tenantId}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch tenant dashboard information');
  }
  return await response.json();
};

export const fetchLeaseSigningUrl = async (tenantId: number) => {
  const response = await fetch(`https://backend.octavian-real-estate.com/get_lease_signing_url/?tenant_id=${tenantId}`, {
    method: "GET",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch listings');
  }
  return await response.json();
};

export const postTenant = async (tenant_id: string | null, userData: any) => {
  // If tenant_id is not null, include it in userData
  if (tenant_id) {
    userData.tenantId = tenant_id;
  }

  const response = await fetch('https://backend.octavian-real-estate.com/post_tenant/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken') || ''
    },
    body: JSON.stringify(userData),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  // If you expect a response from your backend after creating a user, you can return it here
  const responseData = await response.json();
  return responseData.tenant_id;
}

export const postVerificationCodeConfirmation = async (verificationData: { phone_number: string, otp_code: string }) => {
  const response = await fetch('https://backend.octavian-real-estate.com/post_verification_code_confirmation/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(verificationData),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  // Assuming the backend returns a message or data after verifying the OTP code
  const responseData = await response.json();
  return responseData;
}


export const postCreateLease = async (listingId: number, startDate: string, duration: string, tenantId: string, selectedUnits: Unit[]) => {

  return fetch('https://backend.octavian-real-estate.com/post_lease/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      // Add your authorization headers if necessary
    },
    body: JSON.stringify({
      listingId: listingId,
      startDate: startDate,
      duration: duration,
      tenantId: tenantId,
      units: selectedUnits
    }),
  }).then((response) => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }).catch((error) => {
    console.error('Error:', error);
  });
};

export const postTenantReferences = async (tenant_id: number, tenantReferences: Array<Reference>) => {

  const response = await fetch('https://backend.octavian-real-estate.com/post_tenant_references/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ "tenant_id": tenant_id, "references": tenantReferences }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  // If you expect a response from your backend after creating a user, you can return it here
  const responseData = await response.json();
  return responseData;
};

export const postTenantAdditionalOccupants = async (tenant_id: number, additionalOccupants: Array<Occupant>) => {

  try {
    const response = await fetch('https://backend.octavian-real-estate.com/post_tenant_additional_occupants/', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "tenant_id": tenant_id, "additionalOccupants": additionalOccupants }),
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      console.error("Error:", errorMessage);
      // Instead of throwing an error, return the error message
      return {
        error: true,
        message: errorMessage,
      };
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    // Type assertion
    let errMsg = 'An unknown error occurred';
    if (error instanceof Error) {
      errMsg = error.message;
    }
    return {
      error: true,
      message: errMsg,
    };
  }
};


export const postTenantPets = async (tenant_id: number, pets: Array<Pet>) => {

  const response = await fetch('https://backend.octavian-real-estate.com/post_tenant_pets/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ "tenant_id": tenant_id, "pets": pets }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return responseData;
};

export const postTenantEmploymentInfo = async (tenantId: number, employmentInfo: EmploymentInfo[]) => {

  const response = await fetch('https://backend.octavian-real-estate.com/post_tenant_employment_info/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ "tenant_id": tenantId, "employment": employmentInfo }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return responseData;
};


export const postTenantEmergencyContacts = async (tenantId: number, emergencyContacts: EmergencyContact[]) => {

  const response = await fetch('https://backend.octavian-real-estate.com/post_tenant_emergency_contacts/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ "tenant_id": tenantId, "emergencyContacts": emergencyContacts }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return responseData;
};

export const postTenantPersonalReferences = async (tenantId: number, personalReferences: PersonalReference[]) => {

  const response = await fetch('https://backend.octavian-real-estate.com/post_tenant_personal_references/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ "tenant_id": tenantId, "personalReferences": personalReferences }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return responseData;
};

export const postTenantVehicleInformation = async (tenantId: number, vehicles: Vehicle[]) => {

  const response = await fetch('https://backend.octavian-real-estate.com/post_tenant_vehicle_information/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ "tenant_id": tenantId, "vehicles": vehicles }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return responseData;
};

export const postTenantBackgroundInformation = async (tenantId: number, state: BackgroundInformationState) => {

  const response = await fetch('https://backend.octavian-real-estate.com/post_tenant_background_information/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ "tenant_id": tenantId, "backgroundInformation": state }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return responseData;
};

export const postPaymentMethod = async (tenant_id: number, paymentMethodId: string) => {
  const response = await fetch('https://backend.octavian-real-estate.com/post_tenant_payment_method/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ tenant_id: tenant_id, paymentMethodId }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return responseData;
};

export const postNewPaymentMethod = async (tenant_id: number, paymentMethodId: string) => {
  const response = await fetch('https://backend.octavian-real-estate.com/post_new_tenant_payment_method/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ tenant_id: tenant_id, paymentMethodId }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return responseData;
};

export const postDeletePaymentMethod = async (tenant_id: number, paymentMethodId: string) => {
  const response = await fetch('https://backend.octavian-real-estate.com/delete_payment_method/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ tenant_id: tenant_id, card_id: paymentMethodId }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return responseData;
};

export const postSetDefaultPaymentMethod = async (tenant_id: number, paymentMethodId: string) => {
  const response = await fetch('https://backend.octavian-real-estate.com/post_set_default_payment_method/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ tenant_id: tenant_id, card_id: paymentMethodId }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return responseData;
};

// Function for password reset
export const postTenantPasswordReset = async (resetData: { phone_number: string, new_password: string }) => {
  const response = await fetch('https://backend.octavian-real-estate.com/post_tenant_password_reset/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(resetData),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return responseData;
};

export const sendSignupVerificationCode = async (phoneData: { phone_number: string }) => {
  const response = await fetch('https://backend.octavian-real-estate.com/send_signup_verification_code/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(phoneData),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  // Assuming the backend returns a message or data after sending the OTP code
  const responseData = await response.json();
  return responseData;
}

// Function for sending password reset verification code
export const sendPasswordResetVerificationCode = async (phoneData: { phone_number: string }) => {
  const response = await fetch('https://backend.octavian-real-estate.com/send_password_reset_verification_code/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(phoneData),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return responseData;
};

export const postMaintenanceRequest = async (maintenanceRequest: MaintenanceRequest) => {
  const response = await fetch('https://backend.octavian-real-estate.com/post_maintenance_request/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken') || ''
    },
    body: JSON.stringify(maintenanceRequest),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return responseData;
};

export const postTenantPayment = async (paymentData: PaymentData) => {
  const response = await fetch('https://backend.octavian-real-estate.com/post_tenant_payment/', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(paymentData),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return responseData;
};

export const postPropertyTourDetails = async (payload: any) => {
  console.log(payload)
  const response = await fetch('https://backend.octavian-real-estate.com/post_appointment_details/', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
  });

  if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage);
  }

  const responseData = await response.json();
  return responseData;
};