import { useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Card, Typography } from '@mui/material';
import { PersonalReference } from '../../../assets/types/types';
import { postTenantPersonalReferences } from '../../../api/api';
import { useEffect } from 'react';
import { validateAndFormatPhoneNumber, validateZipCode } from '../../../assets/helpers/helpers';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../redux/reducers/ErrorReducer';

interface FormErrors {
  name?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  relationship?: string;
}

const validate = (name: string, value: any) => {
  if (value === null || value === undefined || String(value).trim() === "") {
    return `${name} is required`;
  }
  if (name === "phone") {
    const formattedValue = validateAndFormatPhoneNumber(value);
    if (!formattedValue) {
      return "Invalid phone number";
    }
  }
  if (name === "zip") {
    if (!validateZipCode(value)) {
      return "Invalid Zip Code";
    }
  }

  return null;
};

const validateReference = (reference: PersonalReference) => {
  let errors: FormErrors = {};

  for (let key of Object.keys(reference)) {
    let value = reference[key as keyof PersonalReference];

    const error = validate(key, String(value));
    if (error) {
      errors[key as keyof FormErrors] = error;
    }
  }

  return errors;
};

export const PersonalReferences = () => {
  const [references, setReferences] = useState<Array<{ reference: PersonalReference; errors: FormErrors }>>([]);
  const [clicked, setClicked] = useState(false);
  const { tenant_id } = useParams<{ tenant_id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAddReference = useCallback(async () => {
    let newReference: PersonalReference = {
      name: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      relationship: '',
    };
    let errors = validateReference(newReference);
    setReferences(prevReferences => [...prevReferences, { reference: newReference, errors }]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleAddReference(); // require on reference by default
  }, [handleAddReference]);

  const handleReferenceChange = (index: number, field: keyof PersonalReference, value: string) => {
    let updatedReference = { ...references[index].reference, [field]: value };
    let errors = validateReference(updatedReference);
    setReferences(references.map((ref, i) => i === index ? { reference: updatedReference, errors } : ref));
  };

  const handleNextClick = async () => {
    setClicked(true);

    const hasErrors = references.some(({ errors }) => {
      return Object.values(errors).some(fieldErrors => fieldErrors !== "" && fieldErrors !== undefined);
    });

    if (!hasErrors) {
      const dataToSend = references.map(({ reference }) => reference);
      if (!tenant_id) {
        console.error('Tenant id is not defined');
        return;
      }
      try{

        await postTenantPersonalReferences(parseInt(tenant_id), dataToSend);
      // Change the next page according to your navigation route
      navigate(`/signUp/vehicleInformation/${tenant_id}`);

      }catch{
        dispatch(setErrorReducer("An error occurred posting personal references"));
      }
      
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Typography variant="body1" paragraph>
        Please provide us with any personal Reference information.
      </Typography>
      {references.map((reference, index) => (
        <Card key={index} style={{ marginBottom: "2rem", padding: "2rem" }}>
          <Box key={index} display="flex" flexDirection="column" >
            {/* Reference inputs */}
            <TextField
              name="name"
              label="Name"
              value={reference.reference.name}
              onChange={(e) => handleReferenceChange(index, 'name', e.target.value)}
              error={clicked && !!reference.errors.name}
              helperText={clicked && reference.errors.name}
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="addLine1"
              label="Address Line 1"
              value={reference.reference.addressLine1}
              onChange={(e) => handleReferenceChange(index, 'addressLine1', e.target.value)}
              error={clicked && !!reference.errors.addressLine1}
              helperText={clicked && reference.errors.addressLine1}
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="addLine2"
              label="Address Line 2"
              value={reference.reference.addressLine2}
              onChange={(e) => handleReferenceChange(index, 'addressLine2', e.target.value)}
              error={clicked && !!reference.errors.addressLine2}
              helperText={clicked && reference.errors.addressLine2}
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="city"
              label="City"
              value={reference.reference.city}
              onChange={(e) => handleReferenceChange(index, 'city', e.target.value)}
              error={clicked && !!reference.errors.city}
              helperText={clicked && reference.errors.city}
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="state"
              label="State"
              value={reference.reference.state}
              onChange={(e) => handleReferenceChange(index, 'state', e.target.value)}
              error={clicked && !!reference.errors.state}
              helperText={clicked && reference.errors.state}
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="zip"
              label="Zip Code"
              value={reference.reference.zip}
              onChange={(e) => handleReferenceChange(index, 'zip', e.target.value)}
              error={clicked && !!reference.errors.zip}
              helperText={clicked && reference.errors.zip}
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="phone"
              label="Phone Number"
              value={reference.reference.phone}
              onChange={(e) => handleReferenceChange(index, 'phone', e.target.value)}
              error={clicked && !!reference.errors.phone}
              helperText={clicked && reference.errors.phone}
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="relationship"
              label="Relationship"
              value={reference.reference.relationship}
              onChange={(e) => handleReferenceChange(index, 'relationship', e.target.value)}
              error={clicked && !!reference.errors.relationship}
              helperText={clicked && reference.errors.relationship}
              style={{ marginTop: "2rem" }}
            />
          </Box>
        </Card>
      ))}
      <Button onClick={handleAddReference}>Add Reference</Button>
      <Button variant="contained" color="primary" onClick={handleNextClick}>Next</Button>
    </div>
  );
};