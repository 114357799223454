import { Button, TextField, Box } from '@mui/material';
import { useState } from 'react';
import { sendPasswordResetVerificationCode, postTenantPasswordReset, postVerificationCodeConfirmation } from '../../../api/api'; 
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../redux/reducers/ErrorReducer';

enum ResetStep {
  RequestCode,
  VerifyCode,
  ResetPassword,
}

const ForgotPassword = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [resetStep, setResetStep] = useState(ResetStep.RequestCode);
  const dispatch = useDispatch();

  const handleRequestCode = async (phoneNumber: string) => {
    try {
      const response = await sendPasswordResetVerificationCode({ phone_number: phoneNumber }); 
      if (response.status === 'pending') {
        setResetStep(ResetStep.VerifyCode);  // Go to next step
      } else {
        dispatch(setErrorReducer("An error occurred sendign verification code."));
      }
    } catch (err) {
      console.error(err);
      dispatch(setErrorReducer("An error occurred sendign verification code."));
    }
  };

  const handleVerifyCode = async (phoneNumber: string, otp_code: string) => {
    try {
      const response = await postVerificationCodeConfirmation({ phone_number: phoneNumber, otp_code }); 
      if (response.status === 'approved') {
        setResetStep(ResetStep.ResetPassword);  // Go to next step
      } else {
        dispatch(setErrorReducer("Invalid verification code."));
      }
    } catch (err) {
      console.error(err);
      dispatch(setErrorReducer("Invalid verification code."));
    }
  };

  const handleResetPassword = async (phoneNumber: string, newPassword: string) => {
    try {
      const response = await postTenantPasswordReset({ phone_number: phoneNumber, new_password: newPassword });
      if (response.message === "Password successfully reset") {
        alert("Password successfully reset");
        setResetStep(ResetStep.RequestCode);  // Go back to initial step
      } else {
        dispatch(setErrorReducer("Error resetting password"));
      }
    } catch (err) {
      console.error(err);
      dispatch(setErrorReducer("Error resetting password"));
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Box maxWidth={400} width="100%" p={3}>
        {resetStep === ResetStep.RequestCode && (
          <>
            <TextField 
              label="Phone Number" 
              variant="outlined" 
              fullWidth 
              margin="normal" 
              value={phoneNumber} 
              onChange={e => setPhoneNumber(e.target.value)}
            />
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              onClick={() => handleRequestCode(phoneNumber)}
              style={{marginTop: "0.5rem"}}
            >
              Send Verification Code
            </Button>
          </>
        )}

        {resetStep === ResetStep.VerifyCode && (
          <>
            <TextField 
              label="Verification Code" 
              variant="outlined" 
              fullWidth 
              margin="normal" 
              value={verificationCode} 
              onChange={e => setVerificationCode(e.target.value)}
            />
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              onClick={() => handleVerifyCode(phoneNumber, verificationCode)}
              style={{marginTop: "0.5rem"}}
            >
              Confirm Verification Code
            </Button>
          </>
        )}

        {resetStep === ResetStep.ResetPassword && (
          <>
            <TextField 
              label="New Password" 
              variant="outlined" 
              fullWidth 
              margin="normal" 
              type="password"
              value={newPassword} 
              onChange={e => setNewPassword(e.target.value)}
            />
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              onClick={() => handleResetPassword(phoneNumber, newPassword)}
              style={{marginTop: "0.5rem"}}
            >
              Reset Password
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}

export default ForgotPassword;
