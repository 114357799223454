import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useCallback } from 'react';
import { Button, TextField, Box, Card, Typography } from '@mui/material';
import { postTenantEmergencyContacts } from '../../../api/api';
import { EmergencyContact } from '../../../assets/types/types';
import { fetchTenantEmergencyContacts } from '../../../api/api';
import { validateZipCode, validateAndFormatPhoneNumber } from '../../../assets/helpers/helpers';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../redux/reducers/ErrorReducer';

interface ContactErrors {
    name?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    zip?: string;
    phone?: string;
    relationship?: string;
}

interface EmergencyContactInfoProps {
    isSignUp?: boolean;
}

export const EmergencyContactInfo = ({ isSignUp = true }: EmergencyContactInfoProps) => {
    const [contacts, setContacts] = useState<Array<{ contact: EmergencyContact; errors: ContactErrors; isDisabled: boolean }>>([]);
    const [clicked, setClicked] = useState(false);
    const [isNewContact, setIsNewContact] = useState(false); // New state variable to track whether a new contact is being added or not.
    const { tenant_id } = useParams<{ tenant_id: string }>();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const dispatch = useDispatch();


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const validateContact = useCallback((contact: EmergencyContact) => {
        let errors: ContactErrors = {};

        for (let key of Object.keys(contact)) {
            let value = contact[key as keyof EmergencyContact];
            const error = validate(key, String(value));
            if (error) {
                errors[key as keyof ContactErrors] = error;
            }
        }

        return errors;
    }, []);

    const populateContacts = useCallback(async () => {
        if (tenant_id) {
            try {
                const response = await fetchTenantEmergencyContacts(parseInt(tenant_id));
                const existingContacts = response.emergencyContacts.map((contact: any) => ({
                    contact: {
                        name: contact.name,
                        addressLine1: contact.addressLine1,
                        addressLine2: contact.addressLine2,
                        city: contact.city,
                        state: contact.state,
                        zip: contact.zip,
                        phone: contact.phone,
                        relationship: contact.relationship,
                    },
                    errors: validateContact(contact),
                    isDisabled: true, // Existing contacts are disabled
                }));

                // Ensure that there are at least 2 contacts
                while (existingContacts.length < 2) {
                    const newContact: EmergencyContact = {
                        name: '',
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        zip: '',
                        phone: '',
                        relationship: ''
                    };
                    setIsNewContact(true);
                    const errors = validateContact(newContact);
                    existingContacts.push({ contact: newContact, errors, isDisabled: false });
                }

                setContacts(existingContacts);
            } catch (error) {
                dispatch(setErrorReducer("An error occurred fetching emergency contact information."));
            }
        }
    }, [tenant_id, validateContact, dispatch]);

    useEffect(() => {
        populateContacts();
    }, [populateContacts]);// Adding isNewContact as a dependency so that the form is populated again when a new contact is added.

    const validate = (name: string, value: any) => {
        if (value === null || value === undefined || String(value).trim() === "") {
            return `${name} is required`;
        }
        if (name === 'zip' && !validateZipCode(value)) {
            return "Invalid zip code"
        }

        if (name === 'phone' && !validateAndFormatPhoneNumber(value)) {
            return "Invalid phone number"
        }

        return null;
    };

    const handleAddContact = () => {
        let newContact: EmergencyContact = {
            name: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            relationship: ''
        };
        let errors = validateContact(newContact);
        setContacts([...contacts, { contact: newContact, errors, isDisabled: false }]);
        setIsNewContact(true);
    };

    const handleContactChange = (index: number, updatedContact: EmergencyContact) => {
        let errors = validateContact(updatedContact);
        setContacts(contacts.map((cont, i) => i === index ? { contact: updatedContact, errors, isDisabled: cont.isDisabled } : cont));
    };

    const handleNextClick = async () => {
        setClicked(true);

        const hasErrors = contacts.some(({ errors }) => {
            return Object.values(errors).some(fieldErrors => fieldErrors !== "" && fieldErrors !== undefined);
        });

        if (!hasErrors) {
            const dataToSend = contacts.filter(({ isDisabled }) => !isDisabled).map(({ contact }) => contact);
            if (!tenant_id) {
                console.error('Tenant id is not defined');
                return;
            }
            try{
                await postTenantEmergencyContacts(parseInt(tenant_id), dataToSend);
            }catch (error){
                dispatch(setErrorReducer("An error occurred posting emergency contact information."));
            }
            
            if (isSignUp) {
                navigate(`/signUp/personalReferences/${tenant_id}`);
            } else {
                setIsNewContact(false);
                populateContacts();
            }
        }
    };

    return (
        <div style={{ marginTop: "5rem", marginLeft: isSignUp || isMobile ? "0rem" : "16rem" }}>
            <Typography variant="body1" paragraph>
                Please provide information about your emergency contacts. These persons will be contacted in the event of emergencies.
                {/* Insert fancy legal jargon here */}
            </Typography>
            {contacts.map((contact, index) => (
                <Card key={index} style={{ marginBottom: "2rem", padding: "2rem" }}>
                    <Box key={index} display="flex" flexDirection="column" >
                        {/* Contact inputs */}
                        <TextField
                            name="name"
                            label="Name"
                            value={contact.contact.name}
                            onChange={(e) => handleContactChange(index, { ...contact.contact, name: e.target.value })}
                            error={clicked && !!contact.errors.name}
                            helperText={clicked && contact.errors.name}
                            disabled={contact.isDisabled} // Use the 'isDisabled' flag to disable the TextField
                            style={{ marginTop: "2rem" }}
                        />

                        <TextField
                            name="addLine1"
                            label="Address Line 1"
                            value={contact.contact.addressLine1}
                            onChange={(e) => handleContactChange(index, { ...contact.contact, addressLine1: e.target.value })}
                            error={clicked && !!contact.errors.addressLine1}
                            helperText={clicked && contact.errors.addressLine1}
                            disabled={contact.isDisabled} // Use the 'isDisabled' flag to disable the TextField
                            style={{ marginTop: "2rem" }}
                        />

                        <TextField
                            name="addLine2"
                            label="Address Line 2"
                            value={contact.contact.addressLine2}
                            onChange={(e) => handleContactChange(index, { ...contact.contact, addressLine2: e.target.value })}
                            error={clicked && !!contact.errors.addressLine2}
                            helperText={clicked && contact.errors.addressLine2}
                            disabled={contact.isDisabled} // Use the 'isDisabled' flag to disable the TextField
                            style={{ marginTop: "2rem" }}
                        />

                        <TextField
                            name="city"
                            label="City"
                            value={contact.contact.city}
                            onChange={(e) => handleContactChange(index, { ...contact.contact, city: e.target.value })}
                            error={clicked && !!contact.errors.city}
                            helperText={clicked && contact.errors.city}
                            disabled={contact.isDisabled} // Use the 'isDisabled' flag to disable the TextField
                            style={{ marginTop: "2rem" }}
                        />

                        <TextField
                            name="state"
                            label="State"
                            value={contact.contact.state}
                            onChange={(e) => handleContactChange(index, { ...contact.contact, state: e.target.value })}
                            error={clicked && !!contact.errors.state}
                            helperText={clicked && contact.errors.state}
                            disabled={contact.isDisabled} // Use the 'isDisabled' flag to disable the TextField
                            style={{ marginTop: "2rem" }}
                        />

                        <TextField
                            name="zip"
                            label="Zip"
                            value={contact.contact.zip}
                            onChange={(e) => handleContactChange(index, { ...contact.contact, zip: e.target.value })}
                            error={clicked && !!contact.errors.zip}
                            helperText={clicked && contact.errors.zip}
                            disabled={contact.isDisabled} // Use the 'isDisabled' flag to disable the TextField
                            style={{ marginTop: "2rem" }}
                        />

                        <TextField
                            name='phone'
                            label="Phone"
                            value={contact.contact.phone}
                            onChange={(e) => handleContactChange(index, { ...contact.contact, phone: e.target.value })}
                            error={clicked && !!contact.errors.phone}
                            helperText={clicked && contact.errors.phone}
                            disabled={contact.isDisabled} // Use the 'isDisabled' flag to disable the TextField
                            style={{ marginTop: "2rem" }}
                        />

                        <TextField
                            label="Relationship"
                            value={contact.contact.relationship}
                            onChange={(e) => handleContactChange(index, { ...contact.contact, relationship: e.target.value })}
                            error={clicked && !!contact.errors.relationship}
                            helperText={clicked && contact.errors.relationship}
                            disabled={contact.isDisabled} // Use the 'isDisabled' flag to disable the TextField
                            style={{ marginTop: "2rem" }}
                        />

                    </Box>
                </Card>
            ))}
            <Button onClick={handleAddContact}>Add Emergency Contact</Button>
            {isNewContact && <Button variant="contained" color="primary" onClick={handleNextClick}>Submit</Button>}
        </div>
    );
};