import { styled } from '@mui/system';
export interface Amenity {
  amenity_id: number;
  amenity_name: string;
}

export interface PriceRange {
  min: string;
  max: string;
}

export interface Location {
  lat: string;
  lng: string;
}

export interface Listing {
  id: number;
  property_name: string;
  priceRange: PriceRange;
  bedrooms: number;
  bathrooms: number;
  squareFootage: number;
  location: Location;
  availableFrom: string;
  images: string[];
  property_amenities: Amenity[];
}

export interface Amenity {
  amenity_id: number;
  amenity_name: string;
}

export interface Unit {
  id: number;
  unit_name: string;
  bedrooms: number;
  bathrooms: number;
  squareFootage: number;
  price: string;
  images: string[];
  availableFrom: string;
  amenities: Amenity[];
}

export interface PropertyDetails {
  id: number;
  property_name: string;
  general_description: string;
  neighborhood_description: string;
  bedrooms: number;
  bathrooms: number;
  squareFootage: number;
  location: Location;
  min_price: string;
  max_price: string;
  images: string[];
  availableFrom: string;
  units: Unit[];
  amenities: Amenity[];
}

export interface FormErrors {
  [key: string]: string | null;
}

export interface Reference {
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zip: string;
  type: "Rent" | "Own";
  startDate: Date | null;
  endDate: Date | null;
  contact: {
    name: string;
    phone: string;
    email: string;
  };
}

export interface Occupant {
  firstName: string;
  middleName: string;
  lastName: string;
  birthDate: Date | null;
}

export interface Pet {
  name: string;
  description?: string;
}

export interface EmploymentInfo {
  occupation: string;
  company: string;
  monthlySalary: string;
  supervisorName: string;
  supervisorPhone: string;
  startDate: Date | null;
  endDate: Date | null;
}

export interface EmergencyContact {
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  relationship: string;
}

export interface PersonalReference {
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  relationship: string;
}

export interface Vehicle {
  make: string;
  model: string;
  licenseNumber: string;
  state: string;
  year: string;
}

export interface BackgroundInformationState {
  filedForBankruptcy: boolean;
  refusedToPayRent: boolean;
  evictedOrOwedMoney: boolean;
  propertyName: string;
  city: string;
  state: string;
  landlordName: string;
}

export interface MaintenanceRequest {
  maintenance_request_id?: string;
  title: string;
  description: string;
  status?: string;
  tenant_id: number;
  priority?: number;
  images?: string[];
}

export interface AccountBalance {
  balance_id: number;
  amount_due: string;
  last_updated: Date;
}

export interface PaymentData {
  tenant_id: number,
  amount: string,
  payment_date: Date,
  payment_type: string,
  due_date_id?: number,
  note: string
}

export interface PaymentHistoryEntry {
  payment_id: number;
  amount: number;
  payment_date: string;
  payment_type: string;
  note: string;
  lease_id: number;
}

export interface DueDatesEntry {
  due_date_id: number;
  amount_due: number;
  due_date: string;
  payment_type: string;
  is_paid: boolean;
  lease_id: number;
}

export const CardFieldWrapper = styled('div')({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '4px',
  padding: '18.5px 14px',
  width: '100%',
  maxWidth: '970px', // Add max-width to prevent fields from expanding outside box
  marginBottom: '16px',
});

export const StripeElementOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};
