import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Box, Button  } from '@mui/material';
import { Apartment, DirectionsCar, Work, Help, GridOn} from '@mui/icons-material';
import { Pets as PetsIcon } from '@mui/icons-material';// because of conflict with /signup/pets
import { BasicInformation } from '../../signup/basicInformation';
import { VehicleInformation } from '../../signup/vehicleInformation';
import { Pets } from '../../signup/pets';
import { EmploymentInformation } from '../../signup/employmentInformation';
import { EmergencyContactInfo } from '../../signup/emergencyContactInformation';
import { References } from '../../signup/references';

export const Profile: React.FC = () => {
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const cardData = [
    { label: 'Personal Information', icon: <Apartment /> },
    { label: 'Vehicle Information', icon: <DirectionsCar /> },
    { label: 'Pets', icon: <PetsIcon /> },
    { label: 'Employment Information', icon: <Work /> },
    { label: 'Emergency Contact Information', icon: <Help /> },
    { label: 'References', icon: <GridOn /> }, // Added References card
  ];

  const handleCardClick = (label: string) => {
    setSelectedCard(label);
  };

  const InformationCard: React.FC<{
    icon: React.ReactElement;
    label: string;
    onClick: () => void;
  }> = ({ icon, label, onClick }) => {
    return (
      <Card onClick={onClick}>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            {icon}
            <Typography variant="body1">{label}</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };

  if (selectedCard) {
    let Component;
    switch (selectedCard) {
      case 'Personal Information':
        Component = BasicInformation;
        break;
      case 'Vehicle Information':
        Component = () => <VehicleInformation isSignUp={false} />;
        break;
      case 'Pets':
        Component = () => <Pets isSignUp={false} />;
        break;
      case 'Employment Information':
        Component = () => <EmploymentInformation isSignUp={false} />;
        break;
      case 'Emergency Contact Information':
        Component = () => <EmergencyContactInfo isSignUp={false} />;
        break;
      case 'References':
        Component = () => <References isSignUp={false} />;
        break;
      default:
        Component = () => null; // default case
    }
    return (
      <Box>
        <Button style={{marginTop: "2rem", alignSelf: "left"}} variant="contained" onClick={() => setSelectedCard(null)}>
          Back
        </Button>
        <Component />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding="2rem" marginLeft={ isMobile ? "0rem" : "16rem"}>
      <Typography variant="h4" gutterBottom>
        Profile
      </Typography>
      <Grid container spacing={2}>
        {cardData.map((data) => (
          <Grid item xs={12} sm={6} md={4} key={data.label}>
            <InformationCard
              icon={data.icon}
              label={data.label}
              onClick={() => handleCardClick(data.label)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};