import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { PaymentHistoryEntry } from '../../../../assets/types/types';
import { fetchTenantPayments } from '../../../../api/api';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../../redux/reducers/ErrorReducer';

export const PaymentHistory = () => {
  const { tenant_id } = useParams<{ tenant_id: string }>();
  const [paymentHistory, setPaymentHistory] = useState<PaymentHistoryEntry[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!tenant_id){
      console.error("Tenant Id not found")
      return;
    }

    fetchTenantPayments(parseInt(tenant_id))
      .then((data) => {
        setPaymentHistory(data.payments);
      })
      .catch((error) => {
        dispatch(setErrorReducer("An error occurred fetching payment history.")); 
        console.error('Error fetching tenant payments:', error);
      });
  }, [tenant_id, dispatch]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding="2rem" width="80%" marginLeft="15rem">
      {paymentHistory.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Note</TableCell>
              <TableCell>Lease Id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentHistory.map((payment) => (
              <TableRow key={payment.payment_id}>
                <TableCell>{payment.payment_type}</TableCell>
                <TableCell>{payment.amount}</TableCell>
                <TableCell>{payment.payment_date}</TableCell>
                <TableCell>{payment.note}</TableCell>
                <TableCell>{payment.lease_id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="body1">No payment history available.</Typography>
      )}
    </Box>
  );
};
