import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { DueDatesEntry } from '../../../../assets/types/types';
import { fetchTenantDueDates } from '../../../../api/api';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../../redux/reducers/ErrorReducer';

export const DueDates = () => {
  const { tenant_id } = useParams<{ tenant_id: string }>();
  const [dueDates, setDueDates] = useState<DueDatesEntry[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!tenant_id) {
      console.error("Tenant Id not found")
      return;
    }

    fetchTenantDueDates(parseInt(tenant_id))
      .then((data) => {
        setDueDates(data.due_dates);
      })
      .catch((error) => {
        dispatch(setErrorReducer("An error occurred fetching Due Dates."));
        console.error('Error fetching tenant due dates:', error);
      });
  }, [tenant_id, dispatch]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding="2rem" width="80%" marginLeft="15rem">
      {dueDates.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Amount Due</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Payment Type</TableCell>
              <TableCell>Is Paid</TableCell>
              <TableCell>Lease Id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dueDates.map((dueDate) => (
              <TableRow key={dueDate.due_date_id}>
                <TableCell>{dueDate.amount_due}</TableCell>
                <TableCell>{dueDate.due_date}</TableCell>
                <TableCell>{dueDate.payment_type}</TableCell>
                <TableCell>{dueDate.is_paid ? "Paid" : "Not Paid"}</TableCell>
                <TableCell>{dueDate.lease_id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="body1">No due dates available.</Typography>
      )}
    </Box>
  );
};