import { useState } from 'react';
import { Box, Typography, Card, CardContent, Button, Switch, ToggleButton, ToggleButtonGroup, Divider } from '@mui/material';
import { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { PaymentHistory } from './paymentHistory';
import { PaymentMethods } from './paymentMethods';
import { useParams } from 'react-router-dom';
import { fetchTenantAccountBalance } from '../../../../api/api';
import { NewPaymentForm } from './newPaymentForm';
import { DueDates } from './dueDates';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../../redux/reducers/ErrorReducer';

const stripePromise = loadStripe('pk_test_51N97pgLEssknzdpUKRoGPyMozcHTg5a73opT6PZVekDkNf4gb6mweyGQMzRYCkZeaDcJirTke1zSuBJRYty7Csud00WIftxXls');

const Payments = () => {
    const [isEditingPaymentMethods, setIsEditingPaymentMethods] = useState(false);
    const [isAutopayEnabled, setIsAutopayEnabled] = useState(false);
    const [isMakingPayment, setIsMakingPayment] = useState(false);
    const [selectedView, setSelectedView] = useState('PaymentHistory');
    const [accountBalance, setAccountBalance] = useState(null);  // New state for account balance
    const { tenant_id } = useParams<{ tenant_id: string }>();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const dispatch = useDispatch();


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        // Fetch the tenant's account balance when the component mounts
        const fetchBalance = async () => {
            if (!tenant_id) {
                console.error("Tenant Id is not present")
                return;
            }
            try {
                const balance = await fetchTenantAccountBalance(parseInt(tenant_id));
                setAccountBalance(balance.balance.amount_due);
            } catch (error) {
                dispatch(setErrorReducer("An error occurred fethcing account balance."));
                console.error('Failed to fetch tenant account balance:', error);
            }
        };

        fetchBalance();
    }, [tenant_id, dispatch]);  // Re-fetch when tenantId changes


    const handleViewChange = (event: React.MouseEvent<HTMLElement>, newView: string | null) => {
        if (newView) {
            setSelectedView(newView);
        }
    };

    const handleToggleAutopay = () => {
        setIsAutopayEnabled((prevIsAutopayEnabled) => !prevIsAutopayEnabled);
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" padding="2rem">
            <Typography variant="h4" gutterBottom>
                Payments
            </Typography>

            <Box my={4} display="flex" alignItems="center" justifyContent="space-between" marginLeft={isMobile ? 0 : "15rem"}>
                {!isEditingPaymentMethods && !isMakingPayment ? (
                    <div>
                        <Button variant="outlined" style={{ marginRight: "3rem", marginBottom: isMobile ? "2rem" : "0rem" }} onClick={() => setIsEditingPaymentMethods(true)}>
                            Edit Payment Methods
                        </Button>

                        <Button variant="outlined" style={{ marginRight: "3rem", marginBottom: isMobile ? "2rem" : "0rem" }} onClick={() => setIsMakingPayment(true)}>
                            Make Payment
                        </Button>
                    </div>
                ) : null}

                {isEditingPaymentMethods && (
                    <div style={{marginLeft: "6rem"}}>
                        <Elements stripe={stripePromise}>
                            <Button variant="outlined" style={{ marginRight: "3rem" }} onClick={() => setIsEditingPaymentMethods(false)}>
                                Back
                            </Button>
                            <PaymentMethods />
                        </Elements>
                    </div>

                )}

                {isMakingPayment && (
                     <div style={{marginLeft: "6rem"}}>
                    <div>
                        <Button variant="outlined" style={{ marginRight: "3rem", marginLeft:  "3rem" }} onClick={() => setIsMakingPayment(false)}>
                            Back
                        </Button>
                        <NewPaymentForm setIsMakingPayment={setIsMakingPayment} />
                    </div>
                    </div>
                )}

                <Box display="flex" justifyContent="space-between" >
                    <Typography variant="h6" >Autopay</Typography>

                    <Switch name="autopay" checked={isAutopayEnabled} onChange={handleToggleAutopay} />
                </Box>

            </Box>
            <Divider />
            <Box my={4} width="100%">
                <Card>
                    <CardContent>

                        <Typography variant="h5" gutterBottom>
                            Account Balance: {accountBalance === null ? 'Loading...' : `$${accountBalance}`}
                        </Typography>
                        <ToggleButtonGroup
                            value={selectedView}
                            exclusive
                            onChange={handleViewChange}
                            aria-label="select view"
                        >
                            <ToggleButton value="PaymentHistory" aria-label="payment history">
                                Payment History
                            </ToggleButton>
                            <ToggleButton value="DueDates" aria-label="due dates">
                                Due Dates
                            </ToggleButton>
                        </ToggleButtonGroup>

                        {selectedView === 'PaymentHistory' && <PaymentHistory />}
                        {selectedView === 'DueDates' && <DueDates />}
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

export default Payments;
