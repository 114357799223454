import { useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Button, TextField, Box, Card, Typography } from '@mui/material';
import { postTenantVehicleInformation } from '../../../api/api';
import { Vehicle } from '../../../assets/types/types';
import { fetchTenantVehicleInformation } from '../../../api/api';
import { validateYearInPast } from '../../../assets/helpers/helpers';
import { setErrorReducer } from '../../../redux/reducers/ErrorReducer';
import { useDispatch } from 'react-redux';

interface FormErrors {
  make?: string;
  model?: string;
  licenseNumber?: string;
  state?: string;
  year?: string;
}
interface VehicleInformationProps {
  isSignUp?: boolean;
}

const validate = (name: string, value: any) => {
  if (value === null || value === undefined || String(value).trim() === "") {
    return `${name} is required`;
  }

  if (name === "year" && validateYearInPast(value)) {
    return "Year must be in the past"
  }

  return null;
};

const validateVehicle = (vehicle: Vehicle) => {
  let errors: FormErrors = {};

  for (let key of Object.keys(vehicle)) {
    let value = vehicle[key as keyof Vehicle];

    const error = validate(key, String(value));
    if (error) {
      errors[key as keyof FormErrors] = error;
    }
  }

  return errors;
};

export const VehicleInformation = ({ isSignUp = true }: VehicleInformationProps) => {
  const [vehicles, setVehicles] = useState<Array<{ vehicle: Vehicle; errors: FormErrors; isDisabled: boolean }>>([]);
  const [clicked, setClicked] = useState(false);
  const [isNewVehicle, setIsNewVehicle] = useState(false); // New state variable to track whether a new vehicle is being added or not.
  const { tenant_id } = useParams<{ tenant_id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const populateVehicles = useCallback(async () => {
    if (tenant_id) {
      try {
        const response = await fetchTenantVehicleInformation(parseInt(tenant_id));
        // Map server response to your application's naming convention
        // And add an 'isDisabled' flag to each existing vehicle
        const existingVehicles = response.vehicles.map((vehicle: any) => ({
          vehicle: {
            make: vehicle.make,
            model: vehicle.model,
            licenseNumber: vehicle.license_number, // Note: changed to your convention
            state: vehicle.state,
            year: vehicle.year,
          },
          errors: validateVehicle(vehicle),
          isDisabled: true, // Existing vehicles are disabled
        }));
        setVehicles(existingVehicles);
      } catch (error) {
        dispatch(setErrorReducer("An error occurred fetching vehicle information"));
      }
    }
  }, [tenant_id, dispatch]);

  useEffect(() => {
    populateVehicles();
  }, [tenant_id, populateVehicles]);

  const handleAddVehicle = () => {
    let newVehicle: Vehicle = {
      make: '',
      model: '',
      licenseNumber: '',
      state: '',
      year: '',
    };
    let errors = validateVehicle(newVehicle);
    setVehicles([...vehicles, { vehicle: newVehicle, errors, isDisabled: false }]); // New vehicles are not disabled
    setIsNewVehicle(true);
  };

  const handleVehicleChange = (index: number, field: keyof Vehicle, value: string) => {
    let updatedVehicle = { ...vehicles[index].vehicle, [field]: value };
    let errors = validateVehicle(updatedVehicle);
    setVehicles(vehicles.map((veh, i) => i === index ? { vehicle: updatedVehicle, errors, isDisabled: veh.isDisabled } : veh));
  };

  const handleNextClick = async () => {
    setClicked(true);

    const hasErrors = vehicles.some(({ errors }) => {
      return Object.values(errors).some(fieldErrors => fieldErrors !== "" && fieldErrors !== undefined);
    });

    if (!hasErrors) {
      const dataToSend = vehicles.filter(({ isDisabled }) => !isDisabled).map(({ vehicle }) => vehicle);
      if (!tenant_id) {
        console.error('Tenant id is not defined');
        return;
      }
      try{
        await postTenantVehicleInformation(parseInt(tenant_id), dataToSend);
      } catch(error){
        dispatch(setErrorReducer("An error occurred posting vehicle information"));
      }
      
      // Change the next page according to your navigation route

      if (isSignUp) {
        navigate(`/signUp/backgroundInformation/${tenant_id}`);
      } else {
        setIsNewVehicle(false);
        populateVehicles();

      }
    }
  };

  return (
    <div style={{ marginTop: "5rem", marginLeft: isSignUp || isMobile ? "0rem" : "16rem" }}>
      <Typography variant="body1" paragraph>
        Please enter detils regarding any vehicle that you keep on any leased properties for more than 100 hours per week.
      </Typography>
      {vehicles.map((vehicle, index) => (
        <Card key={index} style={{ marginBottom: "2rem", padding: "2rem" }}>
          <Box key={index} display="flex" flexDirection="column" >
            {/* Vehicle inputs */}
            <TextField
              name="make"
              label="Make"
              value={vehicle.vehicle.make}
              onChange={(e) => handleVehicleChange(index, 'make', e.target.value)}
              error={clicked && !!vehicle.errors.make}
              helperText={clicked && vehicle.errors.make}
              disabled={vehicle.isDisabled} // Use the 'isDisabled' flag to disable the TextField
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="model"
              label="Model"
              value={vehicle.vehicle.model}
              onChange={(e) => handleVehicleChange(index, 'model', e.target.value)}
              error={clicked && !!vehicle.errors.model}
              helperText={clicked && vehicle.errors.model}
              disabled={vehicle.isDisabled} // Use the 'isDisabled' flag to disable the TextField
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="licenseNumber"
              label="License Number"
              value={vehicle.vehicle.licenseNumber}
              onChange={(e) => handleVehicleChange(index, 'licenseNumber', e.target.value)}
              error={clicked && !!vehicle.errors.licenseNumber}
              helperText={clicked && vehicle.errors.licenseNumber}
              disabled={vehicle.isDisabled} // Use the 'isDisabled' flag to disable the TextField
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="state"
              label="State"
              value={vehicle.vehicle.state}
              onChange={(e) => handleVehicleChange(index, 'state', e.target.value)}
              error={clicked && !!vehicle.errors.state}
              helperText={clicked && vehicle.errors.state}
              disabled={vehicle.isDisabled} // Use the 'isDisabled' flag to disable the TextField
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="year"
              label="Year"
              value={vehicle.vehicle.year}
              onChange={(e) => handleVehicleChange(index, 'year', e.target.value)}
              error={clicked && !!vehicle.errors.year}
              helperText={clicked && vehicle.errors.year}
              disabled={vehicle.isDisabled} // Use the 'isDisabled' flag to disable the TextField
              style={{ marginTop: "2rem" }}
            />

          </Box>
        </Card>
      ))}
      <Button onClick={handleAddVehicle}>Add New Vehicle</Button>
      {isNewVehicle && <Button variant="contained" color="primary" onClick={handleNextClick}>Submit</Button>}
    </div>
  );
};
