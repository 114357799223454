import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
    tenant: {
      tenant_id: number;
    }
  }

export interface UserState {
    tenant_id: number
  }
  
  const initialState: UserState = {
    tenant_id: 0,
  };
  


const userSlice = createSlice({
    name: 'userSlice',
    initialState: initialState,
    reducers: {
      setUser(state, action: PayloadAction<User>) {
        const { tenant_id } = action.payload.tenant;
        state.tenant_id= tenant_id;
      },
      resetUser(state, action: PayloadAction<string>) {
        state.tenant_id = 0;
      },
    },
    
  });
  

export const { setUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
