import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Card } from '@mui/material';
import { Occupant } from '../../../assets/types/types';
import { postTenantAdditionalOccupants } from '../../../api/api';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../redux/reducers/ErrorReducer';
import { validateOver18 } from '../../../assets/helpers/helpers';

interface OccupantErrors {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  birthDate?: string | null;
}

export const AdditionalOccupants = () => {
  const [occupants, setOccupants] = useState<Array<{ occupant: Occupant; errors: OccupantErrors }>>([]);
  const [clicked, setClicked] = useState(false);
  const { tenant_id } = useParams<{ tenant_id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validate = (name: string, value: any) => {
    if (value === null || value === undefined || String(value).trim() === "") {
      return `${name} is required`;
    }

    if (name === 'birthDate' && !Date.parse(value)) {
      return 'Date is not valid';
    }

    if (name === "birthDate") {
      if (!validateOver18(value)) {
        return "Person must be over 18 years old";
      }
    }

    return null;
  };

  const validateOccupant = (occupant: Occupant) => {
    let errors: OccupantErrors = {};

    for (let key of Object.keys(occupant)) {
      let value = occupant[key as keyof Occupant];

      // Convert Date objects to string
      if (value instanceof Date) {
        value = value.toISOString();
      }

      const error = validate(key, String(value));
      if (error) {
        errors[key as keyof OccupantErrors] = error;
      }
    }

    return errors;
  };

  const handleAddOccupant = () => {
    let newOccupant: Occupant = {
      firstName: '',
      middleName: '',
      lastName: '',
      birthDate: null,
    };
    let errors = validateOccupant(newOccupant);
    setOccupants([...occupants, { occupant: newOccupant, errors }]);
  };

  const handleOccupantChange = (index: number, updatedOccupant: Occupant) => {
    let errors = validateOccupant(updatedOccupant);
    setOccupants(occupants.map((ocp, i) => i === index ? { occupant: updatedOccupant, errors } : ocp));
  };


  const handleNextClick = async () => {
    setClicked(true);

    // Check if any reference object has validation errors
    const hasErrors = occupants.some(({ errors }) => {
      // Check if any of the error fields are not empty strings or undefined
      return Object.values(errors).some(fieldErrors => {
        if (typeof fieldErrors === 'object' && fieldErrors !== null) {
          return Object.values(fieldErrors).some(error => error !== "" && error !== undefined);
        }
        return fieldErrors !== "" && fieldErrors !== undefined;
      });
    });

    if (!hasErrors) {
      // If there are no errors, gather data to send via API
      const dataToSend = occupants.map(({ occupant }) => occupant);
      // Check that tenant_id is defined
      if (!tenant_id) {
        dispatch(setErrorReducer('Tenant id is not defined'));
        // console.error('Tenant id is not defined');
        return;
      }

      // Send data to API
      // You can replace this with your actual API call
      // await postTenantAdditionalOccupants(parseInt(tenant_id), dataToSend);
      const response = await postTenantAdditionalOccupants(parseInt(tenant_id), dataToSend);
      if (response.error) {
        dispatch(setErrorReducer(response.message));
      } else {
        navigate(`/signUp/pets/${tenant_id}`);
      }
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      {occupants.map((occupant, index) => (
        <Card key={index} style={{ marginBottom: "2rem", padding: "2rem" }}>
          <Box key={index} display="flex" flexDirection="column" >
            <TextField
              name="firstName"
              label="First Name"
              value={occupant.occupant.firstName}
              onChange={(e) => handleOccupantChange(index, { ...occupant.occupant, firstName: e.target.value })}
              error={clicked && !!occupant.errors.firstName}
              helperText={clicked && occupant.errors.firstName}
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="middleName"
              label="Middle Name"
              value={occupant.occupant.middleName}
              onChange={(e) => handleOccupantChange(index, { ...occupant.occupant, middleName: e.target.value })}
              // error={clicked && !!occupant.errors.middleName}
              // helperText={clicked && occupant.errors.middleName}
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="lastName"
              label="Last Name"
              value={occupant.occupant.lastName}
              onChange={(e) => handleOccupantChange(index, { ...occupant.occupant, lastName: e.target.value })}
              error={clicked && !!occupant.errors.lastName}
              helperText={clicked && occupant.errors.lastName}
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name={"birthDate"}
              label={"Birth Date"}
              onChange={(e) => handleOccupantChange(index, { ...occupant.occupant, birthDate: new Date(e.target.value) })}
              error={clicked && !!occupant.errors.birthDate}
              helperText={clicked && occupant.errors.birthDate}
              variant="outlined"
              fullWidth
              margin="normal"
              type={"date"}
              InputLabelProps={{ shrink: true }}
              style={{ marginTop: "2rem" }}
            />
          </Box>
        </Card>
      ))}
      <Button onClick={handleAddOccupant}>Add Occupant</Button>
      <Button variant="contained" color="primary" onClick={handleNextClick}>Next</Button>
    </div>
  );
};