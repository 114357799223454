import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Button, TextField, Typography, Box, Accordion, AccordionSummary, AccordionDetails, Divider, ImageList, ImageListItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MaintenanceRequest } from '../../../../assets/types/types';
import { postMaintenanceRequest } from '../../../../api/api';
import { fetchTenantMaintenanceRequests } from '../../../../api/api';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../../redux/reducers/ErrorReducer';

const MaintenanceRequestPage = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({ title: '', description: '' }); // <-- Add this line
  const [requests, setRequests] = useState<MaintenanceRequest[]>([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { tenant_id } = useParams<{ tenant_id: string }>(); // the one from the url
  const dispatch = useDispatch();

  const fetchRequests = useCallback(async () => {
    if (tenant_id) {
      try {
        const fetchedRequests = await fetchTenantMaintenanceRequests(parseInt(tenant_id));
        setRequests(fetchedRequests.maintenance_requests);
      } catch (error) {
        dispatch(setErrorReducer("An error occurred fetching Maintainence Request Information."));
        console.error(error);
      }
    }
  }, [tenant_id, dispatch]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);



  const handleSubmitRequest = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const errorMessages = {
      title: !title ? "Title is required" : "",
      description: !description ? "Description is required" : "",
    };

    setErrors(errorMessages);

    if (errorMessages.title || errorMessages.description) {
      return;
    }

    if (!tenant_id) {
      console.error('Tenant id is not defined');
      return;
    }

    const newMaintenanceRequest: MaintenanceRequest = {
      title: title,
      description: description,
      tenant_id: parseInt(tenant_id),
    }

    try {
      // const response = await postMaintenanceRequest(newMaintenanceRequest);
      await postMaintenanceRequest(newMaintenanceRequest);
    } catch (error) {
      console.error(error);
    }

    setTitle('');
    setDescription('');
    handleFormToggle();
    await fetchRequests();
  };

  const handleFormToggle = () => {
    setIsFormOpen((prevState) => !prevState);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2, // for space between items
        maxWidth: '700px',
        margin: '0 auto',
      }}
    >
      <Typography variant="h4" align="center" style={{ marginTop: "1rem" }}>
        Maintenance Requests
      </Typography>
      <Box sx={{ marginBottom: 1 }}>
        <Button variant="contained" onClick={handleFormToggle}>
          {isFormOpen ? 'Cancel' : 'New Request'}
        </Button>
      </Box>
      <Divider style={{ marginLeft: "-20rem", width: "400%" }} />
      {/* The form for creating new requests */}
      {isFormOpen && (
        <>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              marginBottom: 2
            }}
          >
            <TextField
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={!!errors.title} // <-- Add this line
            helperText={errors.title} // <-- Add this line
            required
          />

          <TextField
            label="Description"
            variant="outlined"
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={!!errors.description} // <-- Add this line
            helperText={errors.description} // <-- Add this line
            required
          />
            <Button onClick={handleSubmitRequest}>Submit Maintainence Request</Button>
          </Box>
        </>
      )}

      {/* The list of past and in-progress requests */}
      <Typography variant="h5" align="center">
        Past and In-Progress Requests
      </Typography>

      {requests.map((request) => (
        <Accordion key={request.maintenance_request_id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{request.title} ({request.status})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{request.description}</Typography>
            {request.images && 
              <Box sx={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                <ImageList cols={3} rowHeight={50} sx={{ width: '100%', height: '100%' }}>
                  {request.images.map((item, index) => (
                    <ImageListItem key={index}>
                      <img src={item} alt={`Maintenance request ${index + 1}`} loading="lazy" />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>
            }
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default MaintenanceRequestPage;
