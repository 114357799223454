import { useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Button, TextField, Box, Card, CircularProgress, Typography } from '@mui/material';
import { Pet } from '../../../assets/types/types';
import { postTenantPets } from '../../../api/api';
import { fetchTenantPets } from '../../../api/api';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../redux/reducers/ErrorReducer';

interface PetErrors {
  name?: string;
}

interface PetInformationProps {
  isSignUp?: boolean;
}

const validate = (name: string, value: any) => {
  if (value === null || value === undefined || String(value).trim() === "") {
    return `${name} is required`;
  }

  return null;
};

const validatePet = (pet: Pet) => {
  let errors: PetErrors = {};

  const error = validate('name', pet.name);
  if (error) {
    errors.name = error;
  }

  return errors;
};

export const Pets = ({ isSignUp = true }: PetInformationProps) => {
  const [pets, setPets] = useState<Array<{ pet: Pet; errors: PetErrors; isDisabled: boolean }>>([]);
  const [clicked, setClicked] = useState(false);
  const [isNewPet, setIsNewPet] = useState(false);
  const { tenant_id } = useParams<{ tenant_id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const dispatch = useDispatch();


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const populatePets = useCallback(async () => {
    if (tenant_id) {
      try {
        setIsLoading(true);
        const response = await fetchTenantPets(parseInt(tenant_id));
        const existingPets = response.pets.map((pet: any) => ({
          pet: {
            name: pet.name,
            description: pet.description
          },
          errors: validatePet(pet),
          isDisabled: true,
        }));
        setPets(existingPets);
        setIsLoading(false);
      } catch (error) {
        dispatch(setErrorReducer("An error occurred fetching pet information"));
      }
    }
  }, [tenant_id, dispatch]);

  useEffect(() => {
    populatePets();
  }, [tenant_id, populatePets]);

  const handleAddPet = () => {
    let newPet: Pet = {
      name: '',
      description: '',
    };
    let errors = validatePet(newPet);
    setPets([...pets, { pet: newPet, errors, isDisabled: false }]);
    setIsNewPet(true);
  };

  const handlePetChange = (index: number, updatedPet: Pet) => {
    let errors = validatePet(updatedPet);
    setPets(pets.map((pt, i) => i === index ? { pet: updatedPet, errors, isDisabled: pt.isDisabled } : pt));
  };

  const handleNextClick = async () => {
    setClicked(true);

    const hasErrors = pets.some(({ errors }) => !!errors.name);

    if (!hasErrors) {
      const dataToSend = pets.map(({ pet }) => pet);

      if (!tenant_id) {
        console.error('Tenant id is not defined');
        return;
      }
      try{
        await postTenantPets(parseInt(tenant_id), dataToSend);
      } catch (error) {
        dispatch(setErrorReducer("An error occurred posting pet information"));
      }
      

      if (isSignUp) {
        navigate(`/signUp/employmentInformation/${tenant_id}`);
      } else {
        setIsNewPet(false);
        // populatePets();
      }

    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div style={{ marginTop: "5rem", marginLeft: isSignUp || isMobile ? "0rem" : "16rem" }}>
      <Typography variant="body1" paragraph>
        Please provide information any pets that will be on premises for more than 100 hours per week (if your commmunity allows it).
        If you are unsure if your property allows pets, please contact varindra@octavian-software.com
        {/* Insert fancy legal jargon here */}
      </Typography>
      {pets.map((pet, index) => (
        <Card key={index} style={{ marginBottom: "2rem", padding: "2rem" }}>
          <Box key={index} display="flex" flexDirection="column" >
            <TextField
              name="patName"
              label="Pet Name"
              value={pet.pet.name}
              onChange={(e) => handlePetChange(index, { ...pet.pet, name: e.target.value })}
              error={clicked && !!pet.errors.name}
              helperText={clicked && pet.errors.name}
              disabled={pet.isDisabled}
              style={{ marginTop: "2rem" }}
            />

            <TextField
              name="description"
              label="Description (Optional)"
              value={pet.pet.description}
              onChange={(e) => handlePetChange(index, { ...pet.pet, description: e.target.value })}
              disabled={pet.isDisabled}
              style={{ marginTop: "2rem" }}
            />
          </Box>
        </Card>
      ))}
      <Button onClick={handleAddPet}>Add Pet</Button>
      {isNewPet && <Button variant="contained" color="primary" onClick={handleNextClick}>Submit</Button>}
    </div>
  );
};