export const validateAndFormatPhoneNumber = (value: string) => {


    // Extract digits from the input value
    let digitsOnly = value.replace(/\D/g, '');

    const phoneNumberRegex = /^\+?[1-9]\d{0,13}$/; // Validating common number formats

    if (!phoneNumberRegex.test(digitsOnly)) {
        return null;
    }

    // If the extracted digits are less than 10 or more than 15, it's an invalid number
    if (digitsOnly.length < 10 || digitsOnly.length > 15) {
        return null;
    }

    // If the digits are 11 and don't start with '1', it's invalid (assuming North American Numbering Plan)
    if (digitsOnly.length === 11 && digitsOnly[0] !== '1') {
        return null;
    }

    // Add a leading '1' if the digits are exactly 10 (assuming North American Numbering Plan)
    if (digitsOnly.length === 10) {
        digitsOnly = '1' + digitsOnly;
    }

    // Format the digits into E.164 format
    const formattedNumber = `+${digitsOnly}`;
    return formattedNumber;
};


export const validatePassword = (value: string) => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/; // At least 8 characters, at least 1 letter and 1 digit
    if (!passwordRegex.test(value)) {
        return "Password must be at least 8 characters and contain at least one letter and one digit";
    }
    return null;
};

export const validateSocialSecurity = (value: string) => {
    const socialSecurityRegex = /^\d{9}$/; // Assuming a 9-digit SSN format
    if (!socialSecurityRegex.test(value)) {
        return "Invalid social security number";
    }
    return null;
};

export const validateZipCode = (value: string) => {
    const zipCodeRegex = /^\d{5}(?:-\d{4})?$/; // 5 digits and an optional "-1234" (ZIP+4 format)
    if (!zipCodeRegex.test(value)) {
        return null;
    }
    return "Invalid Zip Code";
};

export const validateOver18 = (value: string) => {
    const birthDate = new Date(value);
    const currentDate = new Date();

    const age = currentDate.getFullYear() - birthDate.getFullYear();

    // Check if the person is younger than 18
    if (age < 18) {
        return null;
    }
    return age;
};

export const validateDateInFuture = (value: string) => {
    const inputDate = new Date(value);
    const currentDate = new Date();
  
    // Adding 2 days to the current date
    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + 2);
  
    // Check if the input date is at least 2 days in the future
    if (inputDate < futureDate) {
      return null;
    }
    return inputDate;
  };

  export const validateYearInPast = (year: string) => {
    const currentYear = new Date().getFullYear();
   if (parseInt(year) >= currentYear) {
      return "Year must be in the past";
    }
    return null;
  };