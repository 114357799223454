import React, { useState, useEffect } from "react";
import { Button, Typography } from '@mui/material';
import Lottie from "lottie-react";
import house from "../.././assets/lottie/house.json";
import lease from "../.././assets/lottie/lease.json";

const handleViewListings = () => {
  window.location.href = '/listings';
};

const handleApplyNow = () => {
  window.location.href = '/signUp/basicInformation';
};

export const LandingPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="product" style={{ position: "relative", textAlign: "center", padding: "20px" }}>
      <div style={{ position: "relative", zIndex: 1, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: isMobile ? "column" : "row", marginTop: isMobile ? "0rem" : "-30rem" }}>

        <Lottie
          animationData={house}
          style={isMobile ? { width: '300px', height: '300px' } : { width: '1500px', height: '1500px', marginRight: "50px" }}
        />

        <div>
          <Typography variant="h5" style={{ marginBottom: "2rem" }}>Housing without the hassle. Luxury homes, at regular prices!</Typography>
          <div style={{ marginBottom: "30px" }}>
            <Button variant="contained" color="primary" onClick={handleViewListings} >View Listings</Button>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: isMobile ? "column-reverse" : "row", marginTop: isMobile ? "0rem" : "-20rem" }}>

        <div>
          <Typography variant="h6" >Have questions? Give us a call.</Typography>
          <Typography variant="h6">
            <a href="tel:+17342100451" >
              (734) 210-0451
            </a>
          </Typography>
          <Typography variant="h6" style={{ marginBottom: "2rem" }}>Tired of searching? You're just a few clicks away</Typography>
          <Button variant="contained" color="primary" onClick={handleApplyNow}>Apply Now</Button>
        </div>

        <Lottie
          animationData={lease}
          style={isMobile ? { width: '200px', height: '200px', marginTop: "2rem", marginBottom: "2rem" } : { width: '350px', height: '350px', marginLeft: "150px" }}
        />
      </div>
    </div>
  );
}
