import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../redux/reducers/ErrorReducer';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const ErrorBar = () => {
    //WARN: need to find a way to enforce type of the Error bar. If state.app.Error is not a string, the entire app will go down until its changed. 
    // In this case its a simple fix, call: dispatch(setErrorReducer(''));
    // However in the future lets do a typecheck to prevent this, since it can cause an issue.
    const errorMessage = useSelector((state: RootState) => state.app.Error);
    const dispatch = useDispatch();
    // dispatch(setErrorReducer(''));
    const handleClose = () => {
        dispatch(setErrorReducer(''));

    };

    return (
        <>
            <div style={{ zIndex: 2 }}>
                {Boolean(errorMessage) && (
                    <Alert onClose={handleClose} severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                  </Alert>
                )}
            </div>
        </>
    );
};

export default ErrorBar;