import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, TextField, Select, MenuItem, InputLabel, FormHelperText } from '@mui/material';
import { postTenantPayment } from '../../../../api/api';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../../redux/reducers/ErrorReducer';

interface NewPaymentFormProps {
    setIsMakingPayment: (value: boolean) => void;
}

const paymentTypes = ['Rent', 'Deposit', 'Application Fee', 'Other'];

export const NewPaymentForm = ({ setIsMakingPayment }: NewPaymentFormProps) => {
    const { tenant_id } = useParams<{ tenant_id: string }>();
    const [amount, setAmount] = useState('');
    const [paymentType, setPaymentType] = useState('Rent');
    const [note, setNote] = useState('');
    const [errors, setErrors] = useState({ amount: '', paymentType: '', note: '' });
    const dispatch = useDispatch();

    const validateInputs = () => {
        let valid = true;
        let newErrors = { amount: '', paymentType: '', note: '' };
        if (amount.trim() === '' || isNaN(Number(amount)) || Number(amount) <= 0) {
            valid = false;
            newErrors.amount = 'Invalid amount';
        }
        if (note.trim() === '') {
            valid = false;
            newErrors.note = 'Note is required';
        }
        setErrors(newErrors);
        return valid;
    };

    const makePayment = async () => {
        if (!validateInputs()) return;
        const paymentDate = new Date();
        if (!tenant_id) {
            console.error("tenant Id not found");
            return;
        }
        const paymentData = {
            tenant_id: parseInt(tenant_id),
            amount: amount,
            payment_date: paymentDate, // use Date object directly
            payment_type: paymentType,
            note: note
        };
        try{
            await postTenantPayment(paymentData);
        }catch{
            dispatch(setErrorReducer("An error occurred posting payment."));            
        }
        
        setIsMakingPayment(false);
    };

    return (
        <Box component="form" 
            noValidate 
            autoComplete="off"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxWidth: 500,
                m: 'auto',
                p: 4,
                bgcolor: 'background.paper',
                borderRadius: 2
            }}>
            <TextField 
                label="Amount" 
                value={amount} 
                onChange={(e) => setAmount(e.target.value)} 
                error={!!errors.amount}
                helperText={errors.amount}
            />
            <InputLabel id="payment-type-label">Payment Type</InputLabel>
            <Select
                labelId="payment-type-label"
                value={paymentType}
                onChange={(e) => setPaymentType(e.target.value as string)}
                error={!!errors.paymentType}
            >
                {paymentTypes.map((type, index) => (
                    <MenuItem key={index} value={type}>
                        {type}
                    </MenuItem>
                ))}
            </Select>
            {errors.paymentType && <FormHelperText error>{errors.paymentType}</FormHelperText>}
            <TextField 
                label="Note" 
                value={note} 
                onChange={(e) => setNote(e.target.value)}
                error={!!errors.note}
                helperText={errors.note}
            />
            <Button variant="outlined" onClick={makePayment}>Make Payment</Button>
        </Box>
    );
};
