import { useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Drawer } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PaymentIcon from '@mui/icons-material/Payment';
import DescriptionIcon from '@mui/icons-material/Description';
import LogoutIcon from '@mui/icons-material/Logout';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import MaintenanceRequest from './maintainence/maintaincenceRequests';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MenuIcon from '@mui/icons-material/Menu';
import { Contact } from './contact/contact';
import { Profile } from './profile/Profile';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Dashboard from './dashboard/dashboard';
import Payments from './payments/payments';
import Leases from './leases/leases';

export const PortalHome = () => {
  const [selectedItem, setSelectedItem] = useState('Dashboard');
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSignOut = () => {
    // Logic for handling sign out
    navigate('/signIn');
  };

  return (
    <div>
      {isMobile && (
        <button onClick={handleDrawerToggle}>
          <MenuIcon />
        </button>
      )}
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        anchor="left"
        open={isMobile ? drawerOpen : true}
        onClose={handleDrawerToggle}
        PaperProps={{
          style: {
            height: isMobile ? '100%' : 'calc(100% - 7.5rem)',
            marginTop: '4rem',
            marginLeft: '0rem'
          }
        }}
      >
        <List>
          <ListItem button onClick={() => setSelectedItem('Dashboard')}>
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={() => setSelectedItem('Profile')}>
            <ListItemIcon><AccountCircleIcon /></ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
          <ListItem button onClick={() => setSelectedItem('Payments')}>
            <ListItemIcon><PaymentIcon /></ListItemIcon>
            <ListItemText primary="Payments" />
          </ListItem>
          <ListItem button onClick={() => setSelectedItem('MaintainanceRequests')}>
            <ListItemIcon><BuildOutlinedIcon /></ListItemIcon>
            <ListItemText primary="Maintainence Requests" />
          </ListItem>
          <ListItem button onClick={() => setSelectedItem('Leases')}>
            <ListItemIcon><DescriptionIcon /></ListItemIcon>
            <ListItemText primary="Leases" />
          </ListItem>
          <ListItem button onClick={() => setSelectedItem('Contact')}>
            <ListItemIcon><MailOutlineIcon /></ListItemIcon>
            <ListItemText primary="Contact Us" />
          </ListItem>
          <ListItem button onClick={handleSignOut}>
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItem>
        </List>
      </Drawer>
      {selectedItem === 'Dashboard' && <Dashboard />}
      {selectedItem === 'Payments' && <Payments />}
      {selectedItem === 'Leases' && <Leases />}
      {selectedItem === 'MaintainanceRequests' && <MaintenanceRequest />}
      {selectedItem === 'Profile' && <Profile />}
      {selectedItem === 'Contact' && <Contact />}
    </div>
  );
};