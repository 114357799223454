import { Button, Typography, Card, CardContent, Grid, List, ListItem, Modal, ListItemIcon, ListItemText, ImageList, ImageListItem, Box, Link, TextField, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

import { fetchPropertyDetails } from '../../api/api';
import { PropertyDetails } from '../../assets/types/types';
import { amenityIcons as rawAmenityIcons } from '../../assets/types/amenities';
import React from 'react';
import { useParams } from 'react-router-dom';
import { postPropertyTourDetails } from '../../api/api';
import { setErrorReducer } from '../../redux/reducers/ErrorReducer';
import { useDispatch } from 'react-redux';

const handleApplyNow = () => {
    window.location.href = '/signUp/basicInformation';
};

interface DateRange {
    startTime: string;
    endTime: string;
}

interface DateRangeErrors {
    startTime?: string;
    endTime?: string;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export const Listing = () => {
    const [listing, setListing] = useState<PropertyDetails>();
    const amenityIcons = rawAmenityIcons as Record<number, React.ReactElement>; // Type assertion
    const [open, setOpen] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [name, setName] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const { property_id } = useParams<{ property_id: string }>();
    const dispatch = useDispatch();

    const [dateRanges, setDateRanges] = useState<Array<{ dateRange: DateRange; errors: DateRangeErrors }>>(
        [{
            dateRange: {
                startTime: '',
                endTime: ''
            },
            errors: {
                startTime: '',
                endTime: ''
            }
        }]
    );
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    const createPayload = () => {
        const formattedDateRanges = dateRanges.slice(0, 5).map(dr => dr.dateRange);

        // Fill up any remaining date range slots with null
        while (formattedDateRanges.length < 5) {
            formattedDateRanges.push({ startTime: '', endTime: '' });
        }

        return {
            name,
            phone,
            dates: formattedDateRanges
        };
    };

    const handleAddDateRange = () => {
        const newDateRange = { startTime: '', endTime: '' };
        const isValid = hasAtLeastOneValidDateRange(dateRanges);
        if (isValid) {
            setDateRanges([...dateRanges, { dateRange: newDateRange, errors: {} }]);
        } else {
            setDateRanges([...dateRanges, { dateRange: newDateRange, errors: { startTime: 'At least one date is required', endTime: 'At least one date is required' } }]);
        }

    };

    const hasAtLeastOneValidDateRange = (allDateRanges: Array<{ dateRange: DateRange; errors: DateRangeErrors }>) => {
        return allDateRanges.some(dr => dr.dateRange.startTime && dr.dateRange.endTime);
    };

    const validateAllDateRanges = (allDateRanges: Array<{ dateRange: DateRange; errors: DateRangeErrors }>) => {
        const isValid = hasAtLeastOneValidDateRange(allDateRanges);

        return allDateRanges.map(dr => {
            if (isValid) {
                return { ...dr, errors: {} };
            }

            let errors: DateRangeErrors = {};

            if (!dr.dateRange.startTime || !dr.dateRange.endTime) {
                errors.startTime = 'At least one date is required';
                errors.endTime = 'At least one date is required';
            }

            return { ...dr, errors };
        });
    };

    const handleDateChange = (index: number, field: keyof DateRange, value: string) => {
        const updatedDateRanges = dateRanges.map((dr, i) => {
            if (i === index) {
                return { ...dr, dateRange: { ...dr.dateRange, [field]: value } };
            }
            return dr;
        });

        const validatedDateRanges = validateAllDateRanges(updatedDateRanges);
        setDateRanges(validatedDateRanges);
    };



    //functions for opening and closing the modal
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const handleSubmitRequest = async () => {
        setSubmitted(true);
        const validatedDateRanges = validateAllDateRanges(dateRanges);
        setDateRanges(validatedDateRanges);

        if (hasAtLeastOneValidDateRange(validatedDateRanges)) {
            const payload = createPayload();

            try {
                const responseData = await postPropertyTourDetails(payload);
                console.log(responseData);
                setOpen(false)
                // You can handle success here, like updating state or navigating to a different page
            } catch (error) {
                dispatch(setErrorReducer("An error occurred posting yuor property tour details. Please try again "));
                console.error(error);
                // You can handle errors here, like showing an error message to the user
            }
        }
    };

    useEffect(() => {
        if (!property_id) {
            console.log("Missing property id")
            return;
        }

        fetchPropertyDetails(parseInt(property_id))
            .then(data => {
                setListing(data);  // Update state with fetched data
            })
            .catch(error => {
                console.error('Error:', error);
                dispatch(setErrorReducer("An error occurred fetching property details."));
            });
    }, [property_id, dispatch]);


    if (!listing) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    const ContactBox = () => (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: !isMobile ? 'sticky' : '',
            top: '50%',
            transform: !isMobile ? 'translateY(-50%)' : '',
            borderColor: 'grey.500',
            border: 1,
            borderRadius: 2,
            p: 2,
            mb: 2,
            width: isMobile ? '100%' : '27rem',
            marginLeft: isMobile ? "-1.05rem" : ''
        }} >
            <Typography variant="h4" sx={{ mb: 2 }}>Interested?</Typography>
            <Button variant="contained" color="primary" onClick={handleApplyNow} sx={{ mb: 2 }}>
                Apply Now
            </Button>
            <Button variant="outlined" color="primary" onClick={handleOpen} sx={{ mb: 2 }}>
                Schedule a Tour
            </Button>
            <div style={{ justifyContent: 'center' }}>
                <Grid container spacing={1} alignItems="center" marginLeft={isMobile ? "0rem" : "8rem"}>
                    <Grid item>
                        <PhoneIcon style={{ width: "2rem", height: "2rem" }} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">(734) 210-0451</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={1} alignItems="center" marginLeft={isMobile ? "0rem" : "5rem"} >
                    <Grid item>
                        <EmailIcon style={{ width: "2rem", height: "2rem" }} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            <Link href="mailto:varindra@octavian-software.com">varindra@octavian-software.com</Link>
                        </Typography>
                    </Grid>
                </Grid>

            </div>

        </Box>
    );

    return (

        <div className="product">
            <Grid container style={{ height: '150vh' }} spacing={1}>
                <Grid item xs={12} md={8}>
                    <Card key={listing.id} variant="outlined" style={{ marginBottom: '16px' }}>

                        {/* <div style={{ height: "10rem" }}> */}
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                            <ImageList sx={{ width: 900, height: 350 }} cols={3} rowHeight={70} >
                                {listing.images.map((item, index) => (

                                    <ImageListItem key={index} style={{ paddingTop: (index !== 0 && index !== 1 && index !== 2) ? '10rem' : '0rem' }}>
                                        <img src={item} style={{ height: "18rem", width: "18rem" }} alt={`Listing ${index + 1}`} loading="lazy" />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Box>
                        {/* </div> */}

                        <CardContent>
                            <Box borderColor="grey.500" border={1} borderRadius={2} p={2} mb={2}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography variant="h6">Price: ${listing.min_price} - ${listing.max_price}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography variant="h6">Bedrooms: {listing.bedrooms}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography variant="h6">Bathrooms: {listing.bathrooms}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography variant="h6">Square Footage: {listing.squareFootage} sqft</Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box mt={3}>
                                <Typography variant={isMobile ? "h3" : "h2"}>About</Typography>
                                <Typography variant="body1">{listing.general_description}</Typography>
                            </Box>
                            <Box mt={3}>
                                <Typography variant={isMobile ? "h3" : "h2"}>Neighbourhood</Typography>
                                <Typography variant="body1">{listing.neighborhood_description}</Typography>
                            </Box>
                            <Typography variant={isMobile ? "h3" : "h2"}>Ammenities</Typography>
                            <Grid container spacing={3}> {/* container for the grid */}
                                {listing.amenities.map((amenity, index) => {
                                    const Icon = amenityIcons[amenity.amenity_id];
                                    return (
                                        <Grid item xs={6} sm={4} key={index}> {/* xs={4} will make it take up 1/3 of the grid, creating three columns */}
                                            <List>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        {Icon} {/* Directly render JSX Element */}
                                                    </ListItemIcon>
                                                    <ListItemText primary={amenity.amenity_name} />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    );
                                })}
                            </Grid>


                            {isMobile ? (
                                <ContactBox />
                            ) : (
                                <Button variant="contained" color="primary" onClick={handleApplyNow}>
                                    Apply Now
                                </Button>
                            )}

                        </CardContent>

                    </Card>
                </Grid>

                {isMobile ? (
                    <div></div>
                ) : (
                    <Grid item xs={12} md={4}>
                        <ContactBox />
                    </Grid>
                )}


                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                >
                    <Box sx={{ ...style, width: isMobile ? "90%" : 800 }}>

                        <h2 id="parent-modal-title">Schedule an Tour of this property</h2>
                        <Typography variant={isMobile ? "body1" : "body1"}>Please input your name</Typography>
                        <TextField
                            label="Name"
                            value={name}
                            fullWidth
                            onChange={(e) => setName(e.target.value)}
                            error={!name && submitted}
                            name="landlordName"
                        />

                        <Typography style={{ marginTop: "2rem" }} variant={isMobile ? "body1" : "body1"}>Please input your phone number</Typography>
                        <TextField
                            label="Phone"
                            value={phone}
                            fullWidth

                            onChange={(e) => setPhone(e.target.value)}
                            error={!phone && submitted}
                            name="landlordName"
                        />

                        <Typography style={{ marginTop: "2rem" }} variant={isMobile ? "body1" : "body1"}>Please choose at least one date and time ranges that work well for you</Typography>

                        {dateRanges.map((dr, index) => (
                            <Box key={index} display="flex" flexDirection="row" marginBottom={2}>
                                <TextField
                                    name="startTime"
                                    // label="Start Time"
                                    value={dr.dateRange.startTime}
                                    onChange={(e) => handleDateChange(index, 'startTime', e.target.value)}
                                    error={!!dr.errors.startTime && submitted}
                                    helperText={dr.errors.startTime && submitted}
                                    type="datetime-local"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    style={{ marginRight: "2rem" }}
                                />
                                <TextField
                                    name="endTime"
                                    // label="End Time"
                                    value={dr.dateRange.endTime}
                                    onChange={(e) => handleDateChange(index, 'endTime', e.target.value)}
                                    error={!!dr.errors.endTime && submitted}
                                    helperText={dr.errors.endTime && submitted}
                                    type="datetime-local"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                            </Box>
                        ))}

                        <div>
                            <Button variant="outlined" fullWidth color="primary" onClick={handleAddDateRange} style={{ marginBottom: "1rem" }}>
                                Add Date Range
                            </Button>
                        </div>

                        <div>
                            <Button variant="contained" fullWidth color="primary" onClick={handleSubmitRequest}>
                                Submit Request
                            </Button>
                        </div>

                    </Box>
                </Modal>
            </Grid>
        </div>
    );
};

