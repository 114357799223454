import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button, FormControl, Typography, Box, Link } from '@mui/material';
import { useParams } from 'react-router-dom';
import { postPaymentMethod } from '../../../api/api';
import stripeLogo from '../../../assets/images/powered_by_stripe.svg';  // Import your stripe logo image
import { CardFieldWrapper, StripeElementOptions } from '../../../assets/types/types';
import { setErrorReducer } from '../../../redux/reducers/ErrorReducer';
import { useDispatch } from 'react-redux';
const stripePromise = loadStripe('pk_test_51N97pgLEssknzdpUKRoGPyMozcHTg5a73opT6PZVekDkNf4gb6mweyGQMzRYCkZeaDcJirTke1zSuBJRYty7Csud00WIftxXls');


const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { tenant_id } = useParams<{ tenant_id: string }>(); // parse tenant_id from URL parameters
  const dispatch = useDispatch();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      console.log('Error: Card Element not found.');
      return;
    }
    
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
    });

    if (error) {
      dispatch(setErrorReducer("A Stripe error occurred posting payment method."));
      console.log('[error]', error);
    } else if (tenant_id && paymentMethod) {

      try{
        await postPaymentMethod(parseInt(tenant_id), paymentMethod.id);
        window.location.href = `/signUp/statusPage/${tenant_id}`;
      }catch{
        dispatch(setErrorReducer("An error occurred posting payment method."));
      }
      
      // Send token.id to your server here
    } else {
      dispatch(setErrorReducer("Tenant id is not defined or Token creation failed"));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth>
        {/* <Typography variant="body1">Card Number</Typography> */}
        <CardFieldWrapper>
          <CardNumberElement id="cardNumber" options={StripeElementOptions} />
        </CardFieldWrapper>
      </FormControl>
      <FormControl fullWidth>
        {/* <Typography variant="body1">Card Expiry</Typography> */}
        <CardFieldWrapper>
          <CardExpiryElement id="cardExpiry" options={StripeElementOptions} />
        </CardFieldWrapper>
      </FormControl>
      <FormControl fullWidth>
        {/* <Typography variant="body1">Card CVC</Typography> */}
        <CardFieldWrapper>
          <CardCvcElement id="cardCvc" options={StripeElementOptions} />
        </CardFieldWrapper>
      </FormControl>

      <Box border={1} borderColor="divider" borderRadius={2} p={2} mb={3} maxWidth="1000px" textAlign="left">
        <Typography variant="body1" paragraph>
          I/We, the undersigned, hereby authorize Octavian Software LLC, and its agents to obtain a comprehensive consumer credit report that includes, but is not limited to, credit history, OFAC search, landlord/tenant court record search, criminal record search, and registered sex offender search. I grant permission for the release of information from prior or current landlords, employers, and banking institutions. This investigation is solely for the purpose of tenant screening, and will remain strictly confidential. The report will contain data collected from sources believed to be reliable; however, the accuracy of the information cannot be fully guaranteed.
        </Typography>
        <Typography variant="body1" paragraph>
          I, thereby, exempt Octavian Software LLC and its agents from any liability for any damage resulting from any improper use of this data.
        </Typography>
        <Typography variant="body1" paragraph>
          Please note the following essential points regarding your rights under the Fair Credit Reporting Act:
        </Typography>
        <Typography variant="body1" paragraph>
          • You are entitled to request information on the nature and scope of the investigation.<br />
          • If information in your file has been used against you, you have the right to be informed.<br />
          • You have the right to know the contents of your file; this disclosure may be provided for free.<br />
          • You can request your credit score (please note there might be a charge for this service).<br />
          • You have the right to challenge incomplete or inaccurate information. Consumer reporting agencies are required to correct or complete inaccurate, incomplete, or unverifiable information.
        </Typography>
        <Typography variant="body1" paragraph>
          These reports are prepared by Octavian Software LLC, located at 2370 E stadium Blvd, #2122, Ann Arbor, MI, 48104. To learn more about your rights under the Fair Credit Reporting Act, please visit: <Link>http://www.consumerfinance.gov/learnmore</Link>. Alternatively, you may write to the Consumer Financial Protection Bureau, 1700 G Street N.W., Washington, DC 20552.
        </Typography>
        {/* Should we state: Adaptation from *****? */}
      </Box>


      <Button type="submit" disabled={!stripe}>
        Pay
      </Button>
    </form>
  );
}

export const PaymentInformation = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

      <Elements stripe={stripePromise}>
        <div className="product" style={{ position: "relative", textAlign: "center", padding: "20px" }}>
          <Typography variant="body1" style={{ marginBottom: "2rem" }}>Applicant agrees to pay a non-refundable application fee of: <strong>$50</strong></Typography>
          <Box border={1} borderColor="divider" borderRadius={2} p={2} mb={3} width="1000px">
            <img src={stripeLogo} alt="Powered by Stripe" style={{ display: 'block', marginBottom: "2rem", width: '20%' }} />
            <PaymentForm />
          </Box>
        </div>
      </Elements>


    </div>

  );
}