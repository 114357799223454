import { useState, useEffect } from 'react';
import { Box, Link} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

export const Contact = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
      };

      window.addEventListener("resize", handleResize);

      return () => {
          window.removeEventListener("resize", handleResize);
      };
  }, []);

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            padding="2rem"
            border="1px solid #000"  // Add border
            marginTop="2rem"  // Add marginTop
            marginLeft={isMobile ? "0rem" : "2rem"}  // Add marginLeft
        >
            <Box display="flex" alignItems="center" marginBottom="1rem">
                <PhoneIcon color="action" style={{ marginRight: '0.5rem' }}/>
                <Link href="tel:+17342100451">(734) 210 0451</Link>
            </Box>
            <Box display="flex" alignItems="center">
                <EmailIcon color="action" style={{ marginRight: '0.5rem' }}/>
                <Link href="mailto:varindra@octavian-software.com">varindra@octavian-software.com</Link>
            </Box>
        </Box>
    );
};
