import { Button, TextField, Box, Grid, CircularProgress, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import React, { useState } from 'react';
import { postTenant } from '../../../api/api';
import { sendSignupVerificationCode, postVerificationCodeConfirmation, sendPasswordResetVerificationCode } from '../../../api/api';
import { FormErrors } from '../../../assets/types/types';
import { fetchTenantPersonalInformation } from '../../../api/api';
import { validateAndFormatPhoneNumber, validatePassword, validateOver18 } from '../../../assets/helpers/helpers';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../redux/reducers/ErrorReducer';

interface FormValues {
  firstName: string;
  middleName: string;
  lastName: string;
  workPhone: string;
  homePhone: string;
  dateOfBirth: string;
  socialSecurity: string;
  driversLicense: string;
  email: string;
  password: string;
  confirmPassword: string;
}



export const BasicInformation = () => {

  const [values, setValues] = useState<FormValues>({
    firstName: '',
    middleName: '',
    lastName: '',
    workPhone: '',
    homePhone: '',
    dateOfBirth: '',
    socialSecurity: '',
    driversLicense: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [editMode, setEditMode] = useState(false);
  const [clicked, setClicked] = useState(false);
  // Add a new state to keep track of the loading status
  const [loading, setLoading] = useState(false);
  const { tenant_id } = useParams<{ tenant_id: string }>(); // the one from the url
  const navigate = useNavigate();

  // New state for OTP code
  const [otpCode, setOtpCode] = useState('');

  // New state for OTP error
  const [otpError, setOtpError] = useState<string | null>(null);
  const dispatch = useDispatch();
  // New state for OTP verification step
  const [otpStep, setOtpStep] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
      };

      window.addEventListener("resize", handleResize);

      return () => {
          window.removeEventListener("resize", handleResize);
      };
  }, []);

  useEffect(() => {
    const fetchAndSetTenantData = async () => {
      if (!tenant_id) {
        setEditMode(true);
        return;
      }
      try {
        const data = await fetchTenantPersonalInformation(parseInt(tenant_id)); // Fetch tenant data
        if (data.tenant_info) {
          setValues({
            firstName: data.tenant_info.first_name,
            middleName: data.tenant_info.middle_name,
            lastName: data.tenant_info.last_name,
            workPhone: data.tenant_info.work_phone,
            homePhone: data.tenant_info.home_phone,
            dateOfBirth: data.tenant_info.date_of_birth,
            socialSecurity: data.tenant_info.social_security,
            driversLicense: data.tenant_info.drivers_license,
            email: data.tenant_info.email,
            // Please make sure to handle these values accordingly.
            // I'm setting these to empty strings for now since I didn't see them in your data.
            password: '',
            confirmPassword: '',
          });


          setEditMode(false); // Disable fields if tenant data exists
        } else {
          setEditMode(true); // Enable fields if tenant data doesn't exist
        }
      } catch (error) {
        dispatch(setErrorReducer("Invalid fetching basic information"));
      }
    };

    fetchAndSetTenantData();
  }, [tenant_id, dispatch]);

  // New function for handling OTP change
  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtpCode(event.target.value);
  };

  const validate = (name: string, value: string) => {

    const fieldsToSkipValidation = ['middleName', 'workPhone'];

    if (fieldsToSkipValidation.includes(name)) {
      return null;
    }

    if (value.trim() === "") {
      return `${name} is required`;
    }
    if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      return "Email must be of the correct format";
    }

    if (name === "dateOfBirth") {
      if (!validateOver18(value)) {
        return "Person must be over 18 years old";
      }
    }
    if (name === "homePhone") {
      const formattedValue = validateAndFormatPhoneNumber(value);
      console.log(formattedValue)
      if (!formattedValue) {
        return "Invalid phone number";
      }
    }
    if (name === "socialSecurity") {
      const socialSecurityRegex = /^\d{9}$/;
      if (!socialSecurityRegex.test(value)) {
        return "Invalid social security number";
      }
    }
    if (name === "password" || name === "confirmPassword") {
      console.log(value)
      const passwordError = validatePassword(value);
      console.log(passwordError)
      if (passwordError) {
        return passwordError;
      }
      if (name === "confirmPassword" && values.password !== value) {
        return "Passwords do not match";
      }
    }


    return null;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: validate(name, value),
    });
  };

  // Update your handleClick function
  const handleClick = async () => {
    let validationErrors: FormErrors = {};
    Object.keys(values).forEach((name) => {
      const error = validate(name, values[name as keyof FormValues]);
      if (error) {
        validationErrors[name] = error;
        console.log(name)
      }
    });


    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        tenant_id ? await sendPasswordResetVerificationCode({ phone_number: values.homePhone }) : await sendSignupVerificationCode({ phone_number: values.homePhone });
        setOtpStep(true);
      } catch (error) {
        // handle error, set error state
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(validationErrors);
    }

    setClicked(true);
  };




  const renderTextField = (name: keyof FormValues, label: string, type: string = "text") => {

    return (
      <TextField
        name={name}
        label={label}
        value={values[name]}
        onChange={handleChange}
        error={clicked && !!errors[name]}
        helperText={clicked && errors[name]}
        variant="outlined"
        fullWidth
        margin="normal"
        type={type}
        InputLabelProps={type === "date" ? { shrink: true } : undefined}
        disabled={!editMode} // Fields are disabled if not in edit mode
      />
    );
  };

  // New function for handling OTP submission
  const handleOtpSubmit = async () => {
    setLoading(true);
    try {
      await postVerificationCodeConfirmation({ phone_number: values.homePhone, otp_code: otpCode });
      if (tenant_id) {
        await postTenant(tenant_id, values);
      } else {
        const newId = await postTenant(tenant_id ? tenant_id : null, values);

        if (newId) {
          setLoading(false);
          navigate(`/signUp/leaseSelector/${newId}`);

        }else{
          dispatch(setErrorReducer("An error occurred posting lease information."));
        }
      }
      // clear form or redirect or show success message
    } catch (error: any) {
      if (error.response?.data?.error === 'invalid OTP code') {
        setOtpError('Invalid OTP code');
      }
      // handle the error, show error message
    }

  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (otpStep) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Box maxWidth={800} width="100%" p={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography variant="h6" style={{ marginBottom: "2rem", color: "red" }}>Note: Since this app is not yet released, your verification code has been sent to Varindra. Please contact Varindra for your verification code to complete the rest of the application process</Typography>
              <TextField
                name="otpCode"
                label="OTP Code"
                value={otpCode}
                onChange={handleOtpChange}
                error={!!otpError}
                helperText={otpError}
                variant="outlined"
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleOtpSubmit}
                style={{ marginTop: "0.5rem" }}
              >
                Confirm OTP
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }


  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      // height="100vh"
    >
      <Box maxWidth={800} width="100%" p={3}>
        <Grid item xs={12}>
          {!editMode && tenant_id && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => setEditMode(true)} // Set editMode to true on click
                style={{ marginTop: isMobile ? "6.5rem" : "0.5rem", marginBottom: "4rem" }}
              >
                Edit
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={6}>
            {renderTextField("firstName", "First Name")}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextField("middleName", "Middle Name")}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextField("lastName", "Last Name")}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextField("email", "Email")}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextField("homePhone", "Home Phone")}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextField("workPhone", "Work Phone")}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextField("dateOfBirth", "Date of Birth", "date")}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextField("socialSecurity", "Social Security")}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextField("driversLicense", "Driver's License Number")}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextField("password", "Password", "password")}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderTextField("confirmPassword", "Confirm Password", "password")}
          </Grid>
          {(editMode || !tenant_id) && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleClick}
                style={{ marginTop: "0.5rem" }}
              >
                {tenant_id ? "Update" : "Sign Up"}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

