import { Container, Paper, Typography } from '@mui/material';


export const TermsOfService = () => {
    return (
        <Container maxWidth="md">
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography variant="h4" gutterBottom>
                    TERMS OF SERVICE
                </Typography>
                <Typography variant="h6" gutterBottom>
                    1. ACCEPTANCE OF TERMS
                </Typography>
                <Typography variant="body1" paragraph>
                    By accessing or using the "Octavian Real Estate" application (the "App"), provided by Octavian Software LLC (the "Company"), you agree to comply with and be bound by the following Terms of Service (the "Terms"). If you do not agree to these Terms, please do not use the App.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    2. DESCRIPTION OF SERVICES
                </Typography>
                <Typography variant="body1" paragraph>
                    The App provides real estate management services, including property listings, tenant application processes, tour requests, and management of current rentals. Additional terms may apply to certain services, and such terms will be available with the relevant services.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    3. USER RESPONSIBILITIES
                </Typography>
                <Typography variant="body1" paragraph>
                    You agree to provide accurate, current, and complete information as required for the use of the App. You are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your account.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    4. INTELLECTUAL PROPERTY
                </Typography>
                <Typography variant="body1" paragraph>
                    All content included in or made available through the App, including text, graphics, logos, and software, is the property of the Company or its content suppliers and is protected by international copyright laws.

                </Typography>
                <Typography variant="h6" gutterBottom>
                    5. RESTRICTIONS
                </Typography>
                <Typography variant="body1" paragraph>
                    You may not use the App for any illegal purpose or in violation of any local, state, national, or international law. You agree not to reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, or republish any of the content on our App without our prior written consent.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    6. LIABILITY LIMITATION
                </Typography>
                <Typography variant="body1" paragraph>
                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE COMPANY SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE APP.

                </Typography>

                <Typography variant="h6" gutterBottom>
                    7. TERMINATION
                </Typography>
                <Typography variant="body1" paragraph>
                    The Company reserves the right to terminate or suspend your account and access to the App at its sole discretion, without notice, for conduct that the Company believes violates these Terms or is harmful to the Company, other users of the App, or third parties, or for any other reason.

                </Typography>

                <Typography variant="h6" gutterBottom>
                8. CHANGES TO TERMS
                </Typography>
                <Typography variant="body1" paragraph>
                The Company reserves the right to change these Terms at any time. The latest version of these Terms will be posted on the App, and you are responsible for regularly reviewing these Terms.
                </Typography>

                <Typography variant="h6" gutterBottom>
                9. GOVERNING LAW
                </Typography>
                <Typography variant="body1" paragraph>
                These Terms are governed by the laws of the state in which the Company is registered, without regard to its conflict of law principles.
    </Typography>

    <Typography variant="h6" gutterBottom>
    10. CONTACT INFORMATION
                </Typography>
                <Typography variant="body1" paragraph>
                For any questions about these Terms, please contact us at varindra@octavian-software.com.
                 </Typography>

                {/* Repeat similar structure for other sections */}
            </Paper>
        </Container>
    );
};