import {
    DirectionsCar, // Free Parking
    Wifi, // Wi-Fi
    DryCleaning, // Dryer
    Pets, // Pet Friendly
    Apartment, // Balcony
    Texture, // Hardwood Floors
    GridOn, // import for the Carpet replacement
    DriveEta, // Garage
    Kitchen, // Dishwasher
    Security, // Gated Community
    Pool, // Pool
    FitnessCenter, // Gym
    ThreeDRotation, // 24/7 Front Desk
    Spa, // Spa
    RestaurantMenu, // Restaurant
    LocalBar, // Bar
    AcUnit, // Air Conditioning
    Fireplace, // Heating
    Room, // Kitchen
    Accessible, // Wheelchair Accessible
    Elevator, // Elevator
    LocalLaundryService, // Laundry Facilities
    SmokeFree, // Non-Smoking
    ChildFriendly, // Child Friendly
    FreeBreakfast, // Breakfast Included
    RoomService, // Room Service
    AirportShuttle, // Airport Shuttle
  } from '@mui/icons-material';
  
  export const amenityIcons = {
    1: <DirectionsCar />,
    2: <Wifi />,
    3: <LocalLaundryService />,
    4: <DryCleaning />,
    5: <Pets />,
    6: <Apartment />,
    7: <Texture />,
    8: <GridOn />,
    9: <DriveEta />,
    10: <Kitchen />,
    11: <Security />,
    12: <Pool />,
    13: <FitnessCenter />,
    14: <ThreeDRotation />,
    15: <Spa />,
    16: <RestaurantMenu />,
    17: <LocalBar />,
    18: <AcUnit />,
    19: <Fireplace />,
    20: <Room />,
    21: <Accessible />,
    22: <Elevator />,
    23: <LocalLaundryService />,
    24: <SmokeFree />,
    25: <ChildFriendly />,
    26: <FreeBreakfast />,
    27: <RoomService />,
    28: <AirportShuttle />,
  };
  