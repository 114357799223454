import React from "react";
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ProfileIcon from '@mui/icons-material/Person';
// import Button from '@mui/material/Button';

const CustomNavbar = () => {
  const navigate = useNavigate();

    const handlePortalClick = () => {
      navigate('/signIn'); 
    };

    const handleHomeClick = () => {
      navigate('/')
    };

    const handleListingsClick = () => {
      navigate('/listings')
    };


    return (
        <AppBar position="fixed" style={{ top: 0, zIndex: 2 }}>
            <Toolbar>
        <Box display="flex" alignItems="center">
          <Typography variant="body1" onClick={handleHomeClick} style={{ cursor: 'pointer', marginRight: '16px' }}>
            Home
          </Typography>
          <Typography variant="body1" onClick={handleListingsClick} style={{ cursor: 'pointer', marginRight: '16px' }}>
            Listings
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginLeft="auto">
          <Typography variant="body1" onClick={handlePortalClick} style={{ cursor: 'pointer' }}>
            <ProfileIcon data-testid="profile-icon" style={{height: "2.5rem", width: "2.5rem"}}/>
          </Typography>
        </Box>
      </Toolbar>

        </AppBar>
    );
};

export const NavBar = () => {
    return (
        <div className="navbar">
            <div className="linkss">
                <CustomNavbar />
            </div>
        </div>
    );
};
