import { Box, Card, CardContent, Typography, Button, FormControl, CircularProgress  } from '@mui/material';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements
  } from '@stripe/react-stripe-js';
import { fetchStripeCustomerId, postNewPaymentMethod } from '../../../../api/api';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CardFieldWrapper, StripeElementOptions } from '../../../../assets/types/types';
import { Delete as DeleteIcon } from '@mui/icons-material'; // import Delete icon
import { postDeletePaymentMethod } from '../../../../api/api';
import { postSetDefaultPaymentMethod } from '../../../../api/api';
import { useDispatch } from 'react-redux';
import { setErrorReducer } from '../../../../redux/reducers/ErrorReducer';


export const PaymentMethods = () => {
    const { tenant_id } = useParams<{ tenant_id: string }>();
    const [cards, setCards] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useDispatch();

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        const fetchData = async () => {
            if (!tenant_id) {
                console.log("tenant_id not found")
                return;
            }
            const response = await fetchStripeCustomerId(parseInt(tenant_id));
            setCards(response.cards);
            setLoading(false);
        }

        fetchData();
    }, [tenant_id, dispatch]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) {
            console.log('Stripe.js has not loaded yet.');
            return;
        }

        const cardNumberElement = elements.getElement(CardNumberElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        const cardCvcElement = elements.getElement(CardCvcElement);

        if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
            console.log('Error: Card Element not found.');
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement,
        });

        if (error) {
        } else if (tenant_id && paymentMethod) {
            try{
                await postNewPaymentMethod(parseInt(tenant_id), paymentMethod.id);
            }catch{
                dispatch(setErrorReducer("An error occurred posting payment method."));   
            }
            
        } else {
            console.log("Tenant id is not defined or PaymentMethod creation failed")
        }
    };

    if (loading) {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <CircularProgress />
          </Box>
        );
      }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" padding="2rem">
            Payment Methods
            {cards.map((card, index) => (
                <Card key={index} sx={{ minWidth: 275, marginBottom: 2 }}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {card.brand} ending in {card.last4}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Expiration: {card.exp_month}/{card.exp_year}
                        </Typography>
                        {!card.is_default && 
                        <Button onClick={() => {
                            if (tenant_id) {
                                postSetDefaultPaymentMethod(parseInt(tenant_id), card.id);
                            } else {
                                window.alert('Tenant ID is missing!');
                            }
                        }}>
                            Set as Default
                        </Button>
                    }
                        <Button startIcon={<DeleteIcon />} 
                                variant="outlined" 
                                color="error" 
                                onClick={() => {
                                    if (tenant_id) {
                                        postDeletePaymentMethod(parseInt(tenant_id), card.id);
                                    } else {
                                        window.alert('Tenant ID is missing!');
                                    }
                                }}>
                            Delete
                        </Button>
                    </CardContent>
                </Card>
            ))}
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                    <CardFieldWrapper>
                        <CardNumberElement id="cardNumber" options={StripeElementOptions} />
                    </CardFieldWrapper>
                </FormControl>
                <FormControl fullWidth>
                    <CardFieldWrapper>
                        <CardExpiryElement id="cardExpiry" options={StripeElementOptions} />
                    </CardFieldWrapper>
                </FormControl>
                <FormControl fullWidth>
                    <CardFieldWrapper>
                        <CardCvcElement id="cardCvc" options={StripeElementOptions} />
                    </CardFieldWrapper>
                </FormControl>
                <Button variant="contained" type="submit" disabled={!stripe}>
                    Add Card
                </Button>
            </form>
        </Box>
    );
};
